namespace GSapp.interfaces {
  'use strict';

  export interface ISchedule extends ng.resource.IResource<ISchedule>
  {
    id: number;
    SiteId: number;
    Site: any;
    UnitCode: string;
    GroupId: number;
    CommandGroup: any;
    StartTime: Date;
    StopTime: Date;
    StartCmd: string;
    StopCmd: string;
    Disabled: boolean;
  }

  export interface IScheduleResource extends ng.resource.IResourceClass<ISchedule>
  {
    create(any) : ISchedule,
    findAll() : ISchedule[],
    findOne(number) : ISchedule,
    findAllPending(): ISchedule[],
    findAllSite(number): ISchedule[],
    findAllGroup(number): ISchedule[],
    update(number, any): ISchedule
  }
}