namespace GSapp.controllers {
  'use strict';

  export class ScheduleController extends controllers.BaseController {
    busy: boolean = false;
    loading: boolean = false;
    availableGroups: any = null;
    availableSites: any = null;
    searchText: string = null;
    selectedGroup: any = null;
    selectedSite: any = null;
    selectedUnit: any = null;
    scheduleToEdit: any = {};
    unitType: string = null;
    isGroup:string ="site";
    gridOptions: any = {};
    gridApi: any = {};
    availableTypes: any = {};
    
    static readonly $inject = [
      'SiteResource',
      'CommandGroupResource',
      'ScheduleResource',
      'uiGridConstants',
      'SystemResource',
      '$window', 
      '$q', '$cookies', 'ReportResource'
    ];

    constructor(
      public SiteResource: interfaces.ISiteResource,
      public CommandGroupResource: interfaces.ICommandGroupResource,
      public ScheduleResource: interfaces.IScheduleResource,
      public uiGridConstants: any,
      public SystemResource: interfaces.ISystemResource,   
      public $window: ng.IWindowService,
      public $q: ng.IQService,
      public $cookies: any,
      public ReportResource: interfaces.IReportResource ) {
        super(SystemResource, $window, $q, $cookies, ReportResource);
        
        let self = this;
        self.gridOptions['sched'] = {
          onRegisterApi: function(gridApi){
            self.gridApi['sched'] = gridApi;
          },
          enableFiltering: true,
          enableRowHashing:false,
          enableHorizontalScrollbar: this.uiGridConstants.scrollbars.WHEN_NEEDED,
          rowHeight: this.gridRowHeight,
          data: [],
          columnDefs :[
            { name: 'Site', field: 'Site.Name', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."} },
            { name: 'Group', field: 'CommandGroup.Name', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."} },
            { name: 'Unit', field: 'UnitCode', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."} },
            { name: 'Start Cmd', field: 'StartCmdStr', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."} },
            { name: 'Stop Cmd', field: 'StopCmdStr', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."} },
            { name: 'Start Time', field: 'StartTime', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."},
              cellTemplate: '<div class="ui-grid-cell-contents">{{ COL_FIELD | date:grid.appScope.ctrl.dateFilterFormat:grid.appScope.ctrl.timeZone }}</div>'
            },
            { name: 'Stop Time', field: 'StopTime', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."},
              cellTemplate: '<div class="ui-grid-cell-contents">{{ COL_FIELD | date:grid.appScope.ctrl.dateFilterFormat:grid.appScope.ctrl.timeZone }}</div>'
            },
            { name: 'Started', field: 'Started', width: '92',
              cellTemplate: `<div class="ui-grid-cell-contents">
                <i class="fa-solid fa-circle-xmark origin-center pr-1" ng-show="!row.entity.Started" title="Did not start"></i>
                <i class="fa-solid fa-circle-check origin-center pr-1" ng-show="row.entity.Started" title="Started"></i>
              </div>`,
              filter: {
                term: null,
                type: uiGridConstants.filter.SELECT,
                selectOptions: [ { value: true, label: 'Started' }, { value: false, label: 'Did Not Start'} ]
              }
            },
            { name: 'Stopped', field: 'Stopped', width: '92', 
              cellTemplate: `<div class="ui-grid-cell-contents">
                <i class="fa-solid fa-circle-xmark origin-center pr-1" ng-show="!row.entity.Stopped" title="Did not stop"></i>
                <i class="fa-solid fa-circle-check origin-center pr-1" ng-show="row.entity.Stopped" title="Stopped"></i>
              </div>`,
              filter: {
                term: null,
                type: uiGridConstants.filter.SELECT,
                selectOptions: [ { value: true, label: 'Stopped' }, { value: false, label: 'Did Not Stop'} ]
              }
            },
            { name: 'Created By', field: 'User.LoginName', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."} },
            { name: 'Disabled', field: 'Disabled',
              cellTemplate: `<div class="ui-grid-cell-contents">
                <span class="badge border-white w-full align-middle border-1">{{ COL_FIELD ? "Disabled" : "" }}</span>
                </div>`,
              filter: {
                term: null,
                type: uiGridConstants.filter.SELECT,
                selectOptions: [ { value: true, label: 'Disabled' }, { value: false, label: 'Enabled'} ]
              }
            },
            { name: ' ', width: '32', enableFiltering: false, enableSorting: false,
              cellTemplate: `<div class="ui-grid-cell-contents m-0">
                <button class="w-full w-full m-0", type="button", id="schedDropdownMenuButton", data-bs-toggle="dropdown", aria-expanded="false">
                  <img class="hamburger-vertical-icon"><img>
                </button>
                <ul class="dropdown-menu dropdown-menu-end", aria-labelledby="schedDropdownMenuButton">
                  <li>
                    <button class="dropdown-item" ng-click="grid.appScope.ctrl.openEditSchedModal(row.entity)">Edit</button>
                  </li>
                </ul>
              </div>`
            }
          ]
        }
        self.getData();

      }

      openEditSchedModal = (schedule) => {
        this.loading = true;
        this.selectedSite = null;
        this.unitType = null;
        this.selectedUnit = null;
        this.scheduleToEdit = JSON.parse(JSON.stringify(schedule));
        this.scheduleToEdit.StartTime = moment(this.scheduleToEdit.StartTime);
        this.scheduleToEdit.StopTime = moment(this.scheduleToEdit.StopTime);
        if(this.scheduleToEdit.SiteId) {
          this.isGroup = 'site';
        }
        if(this.scheduleToEdit.GroupId) {
          this.isGroup = 'group';
        }
        this.openSchedModal();
      }

      openCreateSchedModal = () => {
        this.loading = true;
        this.selectedSite = null;
        this.selectedGroup = null;
        this.unitType = null;
        this.selectedUnit = null;
        this.isGroup = 'site';
        let newStartDT = moment().add(5, 'minutes');
        newStartDT.set({second:0,millisecond:0});
        let newStopDT = moment().add(15, 'minutes');
        newStopDT.set({second:0,millisecond:0});
        this.scheduleToEdit = {
          id: null, 
          SiteId: null,
          UnitCode: null,
          GroupId: null,
          StartTime: newStartDT,
          StopTime: newStopDT, 
          StartCmd: null,
          StopCmd: null,
          Started: 0,
          Stopped: 0,
          Disabled: false
        };
        this.openSchedModal();
      }

      openSchedModal = () => {
        let selectPromises = [];
        selectPromises.push(this.CommandGroupResource.findAll().$promise);
        selectPromises.push(this.SiteResource.list().$promise);
        this.$q.all(selectPromises).then((results: any[][]) => {
          if (results[0]) {
            this.availableGroups = results[0];
            if(this.scheduleToEdit.GroupId) {
              this.selectedGroup = this.availableGroups.find(group => group.id == this.scheduleToEdit.GroupId);
            }
          }
          if (results[1]) {
            this.availableSites = results[1];
            if(this.scheduleToEdit.SiteId) {
              this.selectedSite = this.availableSites.find(site => site.id == this.scheduleToEdit.SiteId);
              this.setSite();
            }
          }
          if(this.scheduleToEdit.UnitCode) {
            let unitsplit = this.scheduleToEdit.UnitCode.split('_');
            if(unitsplit.length == 2) {
              let type = unitsplit[0];
              this.unitType = type;
              this.selectedUnit = this.scheduleToEdit.UnitCode;
            } else {
              this.unitType = this.scheduleToEdit.UnitCode;
            }
          }
          this.loading = false;
          $("#editScheduleModal").modal("show");
        });
      }

      checkFields = () => {
        let valid = true;
        // Check times
        if(this.scheduleToEdit.StartTime) {
          if(this.scheduleToEdit.StartTime.isBefore(moment())) {
            toastr.error('Start Time before current time.')
            valid = false;
          }
          if(this.scheduleToEdit.StopTime) {
            if(this.scheduleToEdit.StartTime.isAfter(this.scheduleToEdit.StopTime)) {
              toastr.error('Start Time before Stop Time.')
              valid = false;
            }
          }
        }
        if (!this.scheduleToEdit.GroupId) {
          if(!this.scheduleToEdit.SiteId) {
            toastr.error('Choose either Group or Site for schedule.')
            valid = false;
          } else {
            if(!this.scheduleToEdit.StartTime) {
              toastr.error('Requires a start time.')
              valid = false;
            } else {
              if(!this.scheduleToEdit.StartCmd) {
                toastr.error('Requires a start command.')
                valid = false;
              }
            }
            if(this.scheduleToEdit.StopTime) {
              if(!this.scheduleToEdit.StopCmd) {
                toastr.error('Requires a stop command.')
                valid = false;
              }
            }
          }
        } else {
          this.scheduleToEdit.SiteId = null;
          this.scheduleToEdit.UnitCode = null;
          this.scheduleToEdit.StartCmd = null;
          this.scheduleToEdit.StopCmd = null;
        }
        return valid;
      }

      saveSchedule = () => {
        if(this.checkFields()) {
          if (this.scheduleToEdit.id) {
            this.updateSchedule();
          } else {
            this.createSchedule();
          }
        }
      }

      updateSchedule = () => {
        this.ScheduleResource.update({id: this.scheduleToEdit.id}, this.scheduleToEdit).$promise.then(result => {
          if ( result ) {
            toastr.success('Schedule updated.');
            this.scheduleToEdit = null;
            $("#editScheduleModal").modal("hide");
            this.getData();
          } else {
            toastr.error('Could not update schedule.');
          }
        });
      }

      createSchedule = () => {
        this.ScheduleResource.create(this.scheduleToEdit).$promise.then(result => {
          if ( result ) {
            toastr.success('Schedule created');
            this.scheduleToEdit = null;
            $("#editScheduleModal").modal("hide");
            this.getData();
          } else {
            toastr.error('Could not create schedule.');
          }
        });
      }

      getData = () => {
        this.busy = true;
        this.ScheduleResource.findAll().$promise.then((results) => {
          if (results) {
            this.gridOptions['sched'].data = results;
          }
          this.busy = false;
        });
      };

      setSite = () => {
        this.availableTypes = {};
        if(this.selectedSite) {
          this.scheduleToEdit.SiteId = this.selectedSite.id;
          for (let type of this.unitTypes) {
            if (this.selectedSite.availableCommands[type.value]) {
              this.availableTypes[type.value] = type.name;
            }
          }
        }
      }

    }
    angular.module('gsapp').controller('ScheduleController', ScheduleController);
  }