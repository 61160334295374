namespace GSapp.interfaces {
  'use strict';

  export interface IReport extends ng.resource.IResource<IRole>
  {
    name: string,
    shortId: string,
    tags: string
  }

  export interface IReportResource extends ng.resource.IResourceClass<IReport>
  {
    list(): IReport[];
    getReport(any): any;
  }
}