namespace GSapp {
  'use strict';

  let gsapp = angular.module('gsapp', [
    'ngResource',
    'ngRoute',
    'ngCookies',
    'moment-picker',
    'ui.grid',
    'ui.grid.pinning',
    'nemLogging',
    'ui-leaflet'
  ]);

  angular.module('gsapp')
  .factory('LoginResource', ['$resource', ($resource : ng.resource.IResourceService) : interfaces.ILoginResource => {
    let signupAction : ng.resource.IActionDescriptor = {
      method: 'POST',
      isArray: false,
      url: '/api/auth/signup',
      headers: {
        'Accept': 'application/json'
      }
    };

    let signinAction : ng.resource.IActionDescriptor = {
      method: 'POST',
      isArray: false,
      url: '/api/auth/signin',
      headers: {
        'Accept': 'application/json'
      }
    };

    let signoutAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: false,
      url: '/api/auth/signout',
      headers: {
        'Accept': 'application/json'
      }
    };

    let resendmfaAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: false,
      url: '/api/auth/resendmfa',
      headers: {
        'Accept': 'application/json'
      }
    }

    let sendResetAction : ng.resource.IActionDescriptor = {
      method: 'POST',
      isArray: false,
      url: '/api/auth/sendreset',
      headers: {
        'Accept': 'application/json'
      }
    };

    let checkTokenAction: ng.resource.IActionDescriptor = {
      method: 'POST',
      isArray: false,
      url: '/api/auth/checktoken',
      headers: {
        'Accept': 'application/json'
      }
    };

    let updatePasswordAction: ng.resource.IActionDescriptor = {
      method: 'POST',
      isArray: false,
      url: '/api/auth/updatepwd',
      headers: {
        'Accept': 'application/json'
      }
    };
    
    // Return the resource, include your custom actions
    return <interfaces.ILoginResource> $resource('/api/auth/authenticated', {}, {
      singup: signupAction,
      signin: signinAction,
      singout: signoutAction,
      resendmfa: resendmfaAction,
      sendReset: sendResetAction,
      checkToken: checkTokenAction,
      updatePassword: updatePasswordAction
    });
  }])

  .factory('UserResource', ['$resource', ($resource : ng.resource.IResourceService) : interfaces.IUserResource => {
    let createAction : ng.resource.IActionDescriptor = {
      method: 'POST',
      isArray: false,
      url: '/api/users',
      headers: {
        'Accept': 'application/json'
      }
    };

    let listAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/users',
      headers: {
        'Accept': 'application/json'
      }
    };

    let getAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: false,
      url: '/api/users/:id',
      headers: {
        'Accept': 'application/json'
      }
    };

    let getUserRolesAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/users/roles/:id',
      headers: {
        'Accept': 'application/json'
      }
    };

    let setUserRolesAction : ng.resource.IActionDescriptor = {
      method: 'PUT',
      isArray: false,
      url: '/api/users/roles/:id',
      headers: {
        'Accept': 'application/json'
      }
    };

    let updateAction : ng.resource.IActionDescriptor = {
      method: 'PUT',
      isArray: false,
      url: '/api/users/:id',
      headers: {
        'Accept': 'application/json'
      }
    };

    let addCommAction : ng.resource.IActionDescriptor = {
      method: 'POST',
      isArray: false,
      url: '/api/users/comm/add',
      headers: {
        'Accept': 'application/json'
      }
    };

    let updateCommAction : ng.resource.IActionDescriptor = {
      method: 'PUT',
      isArray: false,
      url: '/api/users/comm/update/:id',
      headers: {
        'Accept': 'application/json'
      }
    };

    let deleteCommAction : ng.resource.IActionDescriptor = {
      method: 'DELETE',
      isArray: false,
      url: '/api/users/comm/del/:id',
      headers: {
        'Accept': 'application/json'
      }
    };

    return <interfaces.IUserResource> $resource('/api/users/:id', { id: '@id' }, {
      create: createAction,
      list: listAction,
      get: getAction,
      getUserRoles: getUserRolesAction,
      setUserRoles: setUserRolesAction,
      update: updateAction,
      addComm: addCommAction,
      updateComm: updateCommAction,
      deleteComm: deleteCommAction
    });
  }])
  .factory('RoleResource', ['$resource', ($resource : ng.resource.IResourceService) : interfaces.IRoleResource => {
    let createAction : ng.resource.IActionDescriptor = {
      method: 'POST',
      isArray: false,
      url: '/api/roles',
      headers: {
        'Accept': 'application/json'
      }
    };

    let listAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/roles',
      headers: {
        'Accept': 'application/json'
      }
    };

    let getAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: false,
      url: '/api/roles/:id',
      headers: {
        'Accept': 'application/json'
      }
    };

    let updateAction : ng.resource.IActionDescriptor = {
      method: 'PUT',
      isArray: false,
      url: '/api/roles/:id',
      headers: {
        'Accept': 'application/json'
      }
    };

    let deleteAction : ng.resource.IActionDescriptor = {
      method: 'DELETE',
      isArray: false,
      url: '/api/roles/:id',
      headers: {
        'Accept': 'application/json'
      }
    };

    let listPermissionAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/roles/permissions/:id',
      headers: {
        'Accept': 'application/json'
      }
    };

    let addPermissionAction : ng.resource.IActionDescriptor = {
      method: 'POST',
      isArray: false,
      url: '/api/roles/permissions',
      headers: {
        'Accept': 'application/json'
      }
    };

    let updatePermissionAction : ng.resource.IActionDescriptor = {
      method: 'PUT',
      isArray: false,
      url: '/api/roles/permissions/:id',
      headers: {
        'Accept': 'application/json'
      }
    };

    let deletePermissionAction : ng.resource.IActionDescriptor = {
      method: 'DELETE',
      isArray: false,
      url: '/api/roles/permissions/:id',
      headers: {
        'Accept': 'application/json'
      }
    };

    return <interfaces.IRoleResource> $resource('/api/roles/:id', { id: '@id' }, {
      create: createAction,
      list: listAction,
      get: getAction,
      update: updateAction,
      deleteRole: deleteAction,
      listPermission: listPermissionAction,
      addPermission: addPermissionAction,
      updatePermission: updatePermissionAction,
      deletePermission: deletePermissionAction
    });
  }])
  .factory('ReportResource', ['$resource', ($resource: ng.resource.IResourceService) : interfaces.IReportResource => {
    let listAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/report/list',
      headers: {
        'Accept': 'application/json'
      }
    };

    let getReportAction : ng.resource.IActionDescriptor = {
      method: 'POST',
      url: '/api/report',
      headers: {
        'Accept': 'application/json'
      }
    };

    return <interfaces.IReportResource> $resource('/api/report', { id: '@id' }, {
      list: listAction,
      getReport: getReportAction
    });
  }])
  .factory('SiteResource', ['$resource', ($resource: ng.resource.IResourceService) : interfaces.ISiteResource => {
    let createAction : ng.resource.IActionDescriptor = {
      method: 'POST',
      isArray: false,
      url: '/api/sites',
      headers: {
        'Accept': 'application/json'
      }
    };

    let listAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/sites',
      headers: {
        'Accept': 'application/json'
      }
    };

    let listminAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/sites/min',
      headers: {
        'Accept': 'application/json'
      }
    };

    let listliveAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/sites/live',
      headers: {
        'Accept': 'application/json'
      }
    };

    let getAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: false,
      url: '/api/sites/:id',
      headers: {
        'Accept': 'application/json'
      }
    };

    let getSiteOneLineAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: false,
      url: '/api/sites/oneline/:id',
      headers: {
        'Accept': 'application/json'
      }
    };

    let getDataByUnitAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/sites/data/byunit/:id/:unitId/:startTime/:endTime',
      headers: {
        'Accept': 'application/json'
      }
    };

    let getDataByUnitFlatAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: false,
      url: '/api/sites/data/byunitflat/:id/:unitId/:startTime/:endTime',
      headers: {
        'Accept': 'application/json'
      }
    };

    let getRawDataAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/sites/data/raw/:id/:startTime/:endTime',
      headers: {
        'Accept': 'application/json'
      }
    };

    let getMapSiteDataAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/sites/data/mapsite/:id',
      headers: {
        'Accept': 'application/json'
      }
    };

    let getDashboardChartDataAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/sites/data/overview/:visibleSites',
      headers: {
        'Accept': 'application/json'
      }
    }

    let getDashboardDataAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/sites/data/dashboard',
      headers: {
        'Accept': 'application/json'
      }
    };

    let getAlarmHistoryAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/sites/alarms/:id/:unitId/:alarmId/:startTime/:endTime',
      headers: {
        'Accept': 'application/json'
      }
    }

    let getAlarmCodesAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/sites/alarmcodes/:id',
      headers: {
        'Accept': 'application/json'
      }
    }

    let updateAction : ng.resource.IActionDescriptor = {
      method: 'PUT',
      isArray: false,
      url: '/api/sites/:id',
      headers: {
        'Accept': 'application/json'
      }
    };

    let updateOneLineAction : ng.resource.IActionDescriptor = {
      method: 'PUT',
      isArray: false,
      url: '/api/sites/oneline/update',
      headers: {
        'Accept': 'application/json'
      }
    };

    let updateAlarmNotifyAction : ng.resource.IActionDescriptor = {
      method: 'PUT',
      isArray: false,
      url: '/api/sites/alarm/notify',
      headers: {
        'Accept': 'application/json'
      }
    };

    let sendCommandAction : ng.resource.IActionDescriptor = {
      method: 'POST',
      isArray: false,
      url: '/api/sites/command',
      headers: {
        'Accept': 'application/json'
      }
    };

    let updateSiteCommandsAction : ng.resource.IActionDescriptor = {
      method: 'POST',
      isArray: false,
      url: '/api/sites/commands/update',
      headers: {
        'Accept': 'application/json'
      }
    };

    return <interfaces.ISiteResource> $resource('/api/sites/:id', { id: '@id', unitId: '@unitId', alarmId: '@alarmId', startTime: '@startTime', endTime: '@endTime' }, {
      create: createAction,
      list: listAction,
      listmin: listminAction,
      listlive: listliveAction,
      get: getAction,
      update: updateAction,
      getSiteOneLine: getSiteOneLineAction,
      getDataByUnit: getDataByUnitAction,
      getDataByUnitFlat: getDataByUnitFlatAction,
      getRawData: getRawDataAction,
      getMapSiteData: getMapSiteDataAction,
      getDashboardChartData: getDashboardChartDataAction,
      getDashboardData: getDashboardDataAction,
      getAlarmHistory: getAlarmHistoryAction,
      getAlarmCodes: getAlarmCodesAction,
      // getRawDataFlat: getRawDataFlatAction,
      updateOneLine: updateOneLineAction,
      updateAlarmNotify: updateAlarmNotifyAction,
      sendCommand: sendCommandAction,
      updateSiteCommands: updateSiteCommandsAction
    });
  }])
  .factory('CustomerResource', ['$resource', ($resource : ng.resource.IResourceService) : interfaces.ICustomerResource => {
    let createAction : ng.resource.IActionDescriptor = {
      method: 'POST',
      isArray: false,
      url: '/api/customers',
      headers: {
        'Accept': 'application/json'
      }
    };

    let listAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/customers',
      headers: {
        'Accept': 'application/json'
      }
    };

    let getAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: false,
      url: '/api/customers/:id',
      headers: {
        'Accept': 'application/json'
      }
    };

    let updateAction : ng.resource.IActionDescriptor = {
      method: 'PUT',
      isArray: false,
      url: '/api/customers/:id',
      headers: {
        'Accept': 'application/json'
      }
    };

    return <interfaces.ICustomerResource> $resource('/api/customers/:id', { id: '@id' }, {
      create: createAction,
      list: listAction,
      get: getAction,
      update: updateAction
    });
  }])
  .factory('UtilityResource', ['$resource', ($resource : ng.resource.IResourceService) : interfaces.IUtilityResource => {
    let createAction : ng.resource.IActionDescriptor = {
      method: 'POST',
      isArray: false,
      url: '/api/utilities',
      headers: {
        'Accept': 'application/json'
      }
    };

    let listAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/utilities',
      headers: {
        'Accept': 'application/json'
      }
    };

    let getAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: false,
      url: '/api/utilities/:id',
      headers: {
        'Accept': 'application/json'
      }
    };

    let updateAction : ng.resource.IActionDescriptor = {
      method: 'PUT',
      isArray: false,
      url: '/api/utilities/:id',
      headers: {
        'Accept': 'application/json'
      }
    };

    return <interfaces.IUtilityResource> $resource('/api/customers/:id', { id: '@id' }, {
      create: createAction,
      list: listAction,
      get: getAction,
      update: updateAction
    });
  }])
  .factory('SystemResource', ['$resource', ($resource : ng.resource.IResourceService) : interfaces.ISystemResource => {
    let listtzAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/sys/tz',
      headers: {
        'Accept': 'application/json'
      }
    };
    let listmaplayersAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/sys/maplayers',
      headers: {
        'Accept': 'application/json'
      }
    };
    let liststormlayersAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/sys/stormlayers',
      headers: {
        'Accept': 'application/json'
      }
    };
    let listcountryAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/sys/country',
      headers: {
        'Accept': 'application/json'
      }
    };
    let liststateAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/sys/state/:id',
      headers: {
        'Accept': 'application/json'
      }
    };
    let listcityAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/sys/city/:id',
      headers: {
        'Accept': 'application/json'
      }
    };
    let listUnitTypesAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/sys/unittypes',
      headers: {
        'Accept': 'application/json'
      }
    };
    let listVarRefsAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: false,
      url: '/api/sys/varrefs',
      headers: {
        'Accept': 'application/json'
      }
    };
    let validateTokenAction : ng.resource.IActionDescriptor ={
      method: 'GET',
      isArray: false,
      url: '/api/sys/validatetoken',
      headers: {
        'Accept': 'application/json'
      }
    }
    return <interfaces.ISystemResource> $resource('/api/sys', { id: '@id' }, {
      listtz: listtzAction,
      listmaplayers: listmaplayersAction,
      liststormlayers: liststormlayersAction,
      listcountry: listcountryAction,
      liststate: liststateAction,
      listcity: listcityAction,
      listUnitTypes: listUnitTypesAction,
      listVarRefs: listVarRefsAction,
      validateToken: validateTokenAction
    });
  }])
  .factory('CommandGroupResource', ['$resource', ($resource : ng.resource.IResourceService) : interfaces.ICommandGroupResource => {
    let createAction : ng.resource.IActionDescriptor = {
      method: 'POST',
      isArray: false,
      url: '/api/cmdgroups',
      headers: {
        'Accept': 'application/json'
      }
    };
    let getOneAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: false,
      url: '/api/cmdgroups/getone/:id',
      headers: {
        'Accept': 'application/json'
      }
    };
    let findAllAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/cmdgroups',
      headers: {
        'Accept': 'application/json'
      }
    };
    let findOneAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/cmdgroups/group/:id',
      headers: {
        'Accept': 'application/json'
      }
    };
    let updateAction : ng.resource.IActionDescriptor = {
      method: 'PUT',
      isArray: false,
      url: '/api/cmdgroups/:id',
      headers: {
        'Accept': 'application/json'
      }
    };
    let startGroupAction : ng.resource.IActionDescriptor = {
      method: 'POST',
      isArray: false,
      url: '/api/cmdgroups/start',
      headers: {
        'Accept': 'application/json'
      }
    };
    let stopGroupAction : ng.resource.IActionDescriptor = {
      method: 'POST',
      isArray: false,
      url: '/api/cmdgroups/stop',
      headers: {
        'Accept': 'application/json'
      }
    };
    return <interfaces.ICommandGroupResource> $resource('/api/cmdgroups', { id: '@id' }, {
      create: createAction,
      getOne: getOneAction,
      findAll: findAllAction,
      findOne: findOneAction,
      update: updateAction,
      startGroup: startGroupAction,
      stopGroup: stopGroupAction
    });
  }])
  .factory('ScheduleResource', ['$resource', ($resource : ng.resource.IResourceService) : interfaces.IScheduleResource => {
    let createAction : ng.resource.IActionDescriptor = {
      method: 'POST',
      isArray: false,
      url: '/api/schedules',
      headers: {
        'Accept': 'application/json'
      }
    };
    let findAllAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/schedules',
      headers: {
        'Accept': 'application/json'
      }
    };
    let findOneAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/schedule/:id',
      headers: {
        'Accept': 'application/json'
      }
    };
    let findAllPendingAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/schedules/pending',
      headers: {
        'Accept': 'application/json'
      }
    };
    let findAllSite : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/schedules/site/:id',
      headers: {
        'Accept': 'application/json'
      }
    };
    let findAllGroup : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/schedules/group/:id',
      headers: {
        'Accept': 'application/json'
      }
    };
    let updateAction : ng.resource.IActionDescriptor = {
      method: 'PUT',
      isArray: false,
      url: '/api/schedules/:id',
      headers: {
        'Accept': 'application/json'
      }
    };
    return <interfaces.IScheduleResource> $resource('/api/schedules', { id: '@id' }, {
      create: createAction,
      findAll: findAllAction,
      findOne: findOneAction,
      findAllPending: findAllPendingAction,
      findAllSite: findAllSite,
      findAllGroup: findAllGroup,
      update: updateAction
    });
  }])
  .factory('EventResource', ['$resource', ($resource : ng.resource.IResourceService) : interfaces.IEventResource => {
    let createAction : ng.resource.IActionDescriptor = {
      method: 'POST',
      isArray: false,
      url: '/api/events',
      headers: {
        'Accept': 'application/json'
      }
    };
    let findAllAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/events',
      headers: {
        'Accept': 'application/json'
      }
    };
    let findOneAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/event/:id',
      headers: {
        'Accept': 'application/json'
      }
    };
    let findAllPendingAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/events/pending',
      headers: {
        'Accept': 'application/json'
      }
    };
    let updateAction : ng.resource.IActionDescriptor = {
      method: 'PUT',
      isArray: false,
      url: '/api/events/:id',
      headers: {
        'Accept': 'application/json'
      }
    };
    return <interfaces.IEventResource> $resource('/api/events', { id: '@id' }, {
      create: createAction,
      findAll: findAllAction,
      findOne: findOneAction,
      findAllPending: findAllPendingAction,
      update: updateAction
    });
  }])
  .factory('RedLionResource', ['$resource', ($resource : ng.resource.IResourceService) : interfaces.IRedLionResource => {
    let createAction : ng.resource.IActionDescriptor = {
      method: 'POST',
      isArray: false,
      url: '/api/redlion',
      headers: {
        'Accept': 'application/json'
      }
    };

    let listAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/redlion',
      headers: {
        'Accept': 'application/json'
      }
    };

    let getAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: false,
      url: '/api/redlion/:id',
      headers: {
        'Accept': 'application/json'
      }
    };

    let updateAction : ng.resource.IActionDescriptor = {
      method: 'PUT',
      isArray: false,
      url: '/api/redlion/:id',
      headers: {
        'Accept': 'application/json'
      }
    };

    return <interfaces.IRedLionResource> $resource('/api/redlion/:id', { id: '@id' }, {
      create: createAction,
      list: listAction,
      get: getAction,
      update: updateAction
    });
  }])
  .factory('SupplierResource', ['$resource', ($resource : ng.resource.IResourceService) : interfaces.ISupplierResource => {
    let createAction : ng.resource.IActionDescriptor = {
      method: 'POST',
      isArray: false,
      url: '/api/suppliers',
      headers: {
        'Accept': 'application/json'
      }
    };

    let listAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/suppliers',
      headers: {
        'Accept': 'application/json'
      }
    };

    let getAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: false,
      url: '/api/suppliers/:id',
      headers: {
        'Accept': 'application/json'
      }
    };

    let updateAction : ng.resource.IActionDescriptor = {
      method: 'PUT',
      isArray: false,
      url: '/api/suppliers/:id',
      headers: {
        'Accept': 'application/json'
      }
    };

    return <interfaces.ISupplierResource> $resource('/api/supplier/:id', { id: '@id' }, {
      create: createAction,
      list: listAction,
      get: getAction,
      update: updateAction
    });
  }])
  .factory('FillResource', ['$resource', ($resource : ng.resource.IResourceService) : interfaces.IFillResource => {
    let createAction : ng.resource.IActionDescriptor = {
      method: 'POST',
      isArray: false,
      url: '/api/fill',
      headers: {
        'Accept': 'application/json'
      }
    };

    let listAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: true,
      url: '/api/fill',
      headers: {
        'Accept': 'application/json'
      }
    };

    let getAction : ng.resource.IActionDescriptor = {
      method: 'GET',
      isArray: false,
      url: '/api/fill/:id',
      headers: {
        'Accept': 'application/json'
      }
    };

    let updateAction : ng.resource.IActionDescriptor = {
      method: 'PUT',
      isArray: false,
      url: '/api/fill/:id',
      headers: {
        'Accept': 'application/json'
      }
    };

    return <interfaces.IFillResource> $resource('/api/fill/:id', { id: '@id' }, {
      create: createAction,
      list: listAction,
      get: getAction,
      update: updateAction
    });
  }])
  toastr.options = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": false,
    "progressBar": false,
    "positionClass": "toast-bottom-full-width",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": 300,
    "hideDuration": 1000,
    "timeOut": 5000,
    "extendedTimeOut": 1000,
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
  };

}