namespace GSapp.interfaces {
  'use strict';

  export interface IEvent extends ng.resource.IResource<IEvent>
  {
    id: number;
    SiteId: number;
    Site: any;
    StartTime: Date;
    StopTime: Date;
    StartStatus: string;
    StopStatus: string;
    ScheduleId: number;
    Schedule: any;
    CreatedById: number;
    CreatedBy: any;
  }

  export interface IEventResource extends ng.resource.IResourceClass<IEvent>
  {
    create(any) : IEvent,
    findAll() : IEvent[],
    findOne(number) : IEvent,
    findAllPending(): IEvent[],
    update(number, any): IEvent
  }
}