namespace GSapp.controllers {
  'use strict';

  export class ProfileController extends controllers.BaseController {
    busy: boolean = false;
    selectedUser: any = null;
    lockedOnly: boolean = false;
    passwordError: string = null;
    roleList: any = [];
    searchText: string = null;
    strongRegularExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    mediumRegularExp = /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/;
    pwdBackground: string = "bg-danger";
    showHideString: string = "eye-closed";
    showHideResetString: string = "eye-closed";
    newPwdOk: boolean = false;
    newPwdMedium: boolean = false;
    newPwdStrong: boolean = false;
    showPassword: boolean = false;
    showResetPassword: boolean = false;
    passwordStrength: string = "";
    passwordsMatch: boolean = false;
    gridOptions: any = {};
    gridApi: any = {};
    loginform: any;
    newCommType: string = null;
    newCommValue: string = null;
    newCommOrder: number = 0;

    static readonly $inject = [
      'UserResource',
      'LoginResource',
      'SystemResource',
      '$window',
      '$q', '$cookies', 'ReportResource'
    ];

    constructor(
      public UserResource: interfaces.IUserResource,
      public LoginResource: interfaces.ILoginResource,
      public SystemResource: interfaces.ISystemResource,
      public $window: ng.IWindowService,
      public $q: ng.IQService,
      public $cookies: any,
      public ReportResource: interfaces.IReportResource) {
        super(SystemResource, $window, $q, $cookies, ReportResource);

      this.getData();
    }

    openEditModal = () => {
      this.selectedUser['newPassword'] = null;
      this.selectedUser['newPassword2'] = null;
      $("#editModal").modal("show");
    }

    //- Need to rework the password setting IF it's been updated, otherwise update the user with no password change.
    updateUser = () => {
      let userToUpdate = {
        id: this.selectedUser.id
      };
      if(this.loginform.loginName.$dirty) {
        userToUpdate['LoginName'] = this.selectedUser.LoginName;
      }
      if(this.loginform.firstName.$dirty) {
        userToUpdate['FirstName'] = this.selectedUser.FirstName;
      }
      if(this.loginform.lastName.$dirty) {
        userToUpdate['LastName'] = this.selectedUser.LastName;
      }
      if (this.loginform.newPassword.$dirty) {
        if (this.passwordsMatch) {
          if (this.newPwdOk) {
            if (this.selectedUser.id) {
              userToUpdate['Password'] = this.selectedUser.newPassword;
            }
          }
        }
      }
      if(this.loginform.inputTheme.$dirty) {
        userToUpdate['Theme'] = this.selectedUser['Theme'];
      }
      if(this.loginform.inputUnits.$dirty) {
        userToUpdate['Units'] = this.selectedUser['Units'];
      }
      if(this.loginform.inputDateFormat.$dirty) {
        userToUpdate['DateFormat'] = this.selectedUser['DateFormat'];
      }
      if (this.loginform.$valid) {
        this.saveUser(userToUpdate);
      } else {
        toastr.error("Unable to save. Please check that all fields are filled in.")
      }
    }

    saveUser = (userToUpdate) => {
      this.UserResource.update({ id: userToUpdate.id }, userToUpdate).$promise
        .then(result => {
          if (result) {
            toastr.success('User ' + this.selectedUser.FirstName + ' updated');
            this.setUserUnits(this.selectedUser.Units);
            this.setUserDateFormat(this.selectedUser.DateFormat);
            this.setUserTheme(this.selectedUser.Theme);
            this.selectedUser = null;
            $("#editModal").modal("hide");
            this.getData();
          } else {
            toastr.error('Could not update user ' + this.selectedUser.FirstName);
          }
        });
    }

    setUserUnits = (units) => {
      this.units = units;
      this.$cookies.put('Units', units);
    }

    setUserDateFormat = (dateformat) => {
      this.dateOnlyFilterFormat = dateformat;
      this.dateFilterFormat = dateformat + " HH:mm Z";
      this.$cookies.put('DateFormat', this.dateOnlyFilterFormat);
      this.$cookies.put('DateTimeFormat', this.dateFilterFormat);
    }

    setUserTheme = (theme) => {
      let removeTheme = theme === 'light' ? 'dark' : 'light';
      this.$window.document.body.classList.toggle(theme, true);
      this.$window.document.body.classList.toggle(removeTheme, false);
      this.theme = theme;
      this.$cookies.put('Theme', this.theme);
    }

    showHidePassword = () => {
      this.showPassword = !this.showPassword;
      this.showHideString = this.showPassword ? "eye-open" : "eye-closed";
    }

    showHideResetPassword = () => {
      this.showResetPassword = !this.showResetPassword;
      this.showHideResetString = this.showResetPassword ? "eye-open" : "eye-closed";
    }

    addComm = () => {
      this.selectedUser.Comms.push({
        id: null,
        UserId: this.selectedUser.id,
        Type: this.newCommType,
        Value: this.newCommType !== 'email' ? this.deformatPhoneNumber(this.newCommValue) : this.newCommValue,
        SortOrder: this.newCommOrder,
        deleted: false,
        new: true,
        dirty: true
      });
      this.newCommType = null;
      this.newCommValue = null;
      this.newCommOrder++;
    }

    deleteComm = (index) => {
      this.selectedUser.Comms[index]['deleted'] = true;
      this.selectedUser.Comms[index]['dirty'] = true;
    }

    moveUp = (index) => {
      if (index > 0) {
        let prevOrder = this.selectedUser.Comms[index].SortOrder;
        this.selectedUser.Comms[index].SortOrder = this.selectedUser.Comms[index - 1].SortOrder;
        this.selectedUser.Comms[index - 1].SortOrder = prevOrder;
        this.selectedUser.Comms[index]['dirty'] = true;
        this.selectedUser.Comms[index - 1]['dirty'] = true;
      }
      this.selectedUser.Comms = this.sortComms(this.selectedUser.Comms);
    }

    moveDown = (index) => {
      if (index <= this.selectedUser.Comms.length) {
        let prevOrder = this.selectedUser.Comms[index].SortOrder;
        this.selectedUser.Comms[index].SortOrder = this.selectedUser.Comms[index + 1].SortOrder;
        this.selectedUser.Comms[index + 1].SortOrder = prevOrder;
        this.selectedUser.Comms[index]['dirty'] = true;
        this.selectedUser.Comms[index + 1]['dirty'] = true;
      }
      this.selectedUser.Comms = this.sortComms(this.selectedUser.Comms);
    }

    sortComms = (comms) => {
      return comms.sort((a, b) => {
        return a.SortOrder - b.SortOrder;
      });
    }

    saveUserComms = () => {
      this.busy = true;
      let selectPromises = [];
      for (const comm of this.selectedUser.Comms) {
        if (comm.deleted) {
          selectPromises.push(this.UserResource.deleteComm({ id: comm.id }).$promise);
        } else if (comm.new) {
          selectPromises.push(this.UserResource.addComm(comm).$promise);
        } else if (comm.dirty) {
          selectPromises.push(this.UserResource.updateComm({ id: comm.id }, comm).$promise)
        }
      }
      this.$q.all(selectPromises).then((results: any[][]) => {
        this.busy = false;
        toastr.success("Comms saved.");
        $("#commsModal").modal("hide");
        this.getData();
      });
    }

    openCommsModal = (user) => {
      this.newCommOrder = this.selectedUser.Comms.length;
      $("#commsModal").modal("show");
    }

    getData = () => {
      let self = this;
      self.busy = true;
      this.UserResource.get({ id: 0 }).$promise.then(result => {
        if (result) {
          self.selectedUser = result;
        }
        self.busy = false;
      });
    }

    checkNewPassword = (password) => {
      this.newPwdOk = false;
      this.newPwdMedium = false;
      this.newPwdStrong = false;
      if (this.strongRegularExp.test(password)) {
        this.newPwdOk = true;
        this.newPwdStrong = true;
      } else if (this.mediumRegularExp.test(password)) {
        this.newPwdOk = true;
        this.newPwdMedium = true;
      }
    }

    comparePasswords = (password, newpassword) => {
      this.passwordsMatch = false;
      if (password == newpassword) {
        this.passwordsMatch = true;
      }
    }

  }
  angular.module('gsapp').controller('ProfileController', ProfileController);
}