namespace GSapp.interfaces {
  'use strict';

  export interface IFill extends ng.resource.IResource<IFill>
  {
    id: number;
    SiteId: number;
    Units : string;
    SupplierId: number;
    OrderedGalDIESEL: number;
    OrderedGalDEF: number;
    OrderedGalPROP: number;
    FillGalDIESEL: number;
    FillGalDEF: number;
    FillGalPROP: number;
    PriceDIESEL: number;
    PriceDEF: number;
    PricePROP: number;
    TaxDIESEL: number;
    TaxDEF: number;
    TaxPROP: number;
    OrderDate: Date;
    ScheduleDate: Date;
    On: boolean;
    FillDate: Date;
    Cancelled: boolean;
    Deleted: boolean;
    Notes: string;
    OrderedBy: number;
    UpdatedBy: number;
  }

  export interface IFillResource extends ng.resource.IResourceClass<IFill>
  {
    create(any): any;
    list(): any[];
    get(): any;
    get(number): any;
    update(number, any) : any;
  }
}