namespace GSapp.controllers {
  'use strict';

  export class RedLionController extends controllers.BaseController {
    busy: boolean = false;
    loading: boolean = false;
    selectedRedLion: any = null;
    selectedSite: any = null;
    siteList: any = [];
    searchText: string = null;
    gridOptions: any = {};
    gridApi: any = {};

    static readonly $inject = [
      'RedLionResource',
      'SiteResource',
      'uiGridConstants',
      'SystemResource',
      '$window', 
      '$q', '$cookies', 'ReportResource'
    ];

    constructor(
      public RedLionResource: interfaces.IRedLionResource,
      public SiteResource: interfaces.ISiteResource,
      public uiGridConstants: any,
      public SystemResource: interfaces.ISystemResource,   
      public $window: ng.IWindowService,
      public $q: ng.IQService,
      public $cookies: any,
      public ReportResource: interfaces.IReportResource ) {
        super(SystemResource, $window, $q, $cookies, ReportResource);
        
        let self = this;

        self.gridOptions['redlion'] = {
          onRegisterApi: function(gridApi){
            self.gridApi['redlion'] = gridApi;
          },
          enableFiltering: true,
          enableRowHashing:false,
          enableHorizontalScrollbar: this.uiGridConstants.scrollbars.WHEN_NEEDED,
          rowHeight: this.grid2RowHeight,
          data: [],
          columnDefs :[
            { name: 'Model/Serial', field: 'Model', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."},
              cellTemplate: `<div class="ui-grid-cell-contents flex flex-col">
                <span>{{ row.entity.Model }}</span>
                <span>{{ row.entity.Serial }}</span>
                </div>`
            },
            { name: 'Cell Model/Serial', field: 'CellModel', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."},
            cellTemplate: `<div class="ui-grid-cell-contents flex flex-col">
              <span>{{ row.entity.CellModel }}</span>
              <span>{{ row.entity.CellSerial }}</span>
              </div>` },
            { name: 'UPC/IMEI', field: 'UPC', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."},
            cellTemplate: `<div class="ui-grid-cell-contents flex flex-col">
              <span>{{ row.entity.UPC }}</span>
              <span>{{ row.entity.IMEI }}</span>
              </div>` },
            { name: 'IP/Phone#', field: 'IP', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."},
            cellTemplate: `<div class="ui-grid-cell-contents flex flex-col">
              <span>{{ row.entity.IP }}</span>
              <span>{{ row.entity.PhoneNumber }}</span>
              </div>` },
            { name: 'ICCID', field: 'ICCID', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."} },
            { name: 'APN/Password', field: 'APN', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."},
            cellTemplate: `<div class="ui-grid-cell-contents flex flex-col">
              <span>{{ row.entity.APN }}</span>
              <span>{{ row.entity.DefaultPassword }}</span>
              </div>` },
            { name: 'Site', field: 'Site', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."},
              cellTemplate: `<div class="ui-grid-cell-contents">
                {{ row.entity.SiteId ? row.entity.Site.Name : ''}}
                </div>`
            },
            { name: ' ', width: '32', enableFiltering: false, enableSorting: false,
            cellTemplate: `<div class="ui-grid-cell-contents m-0">
              <button class="w-full w-full m-0", type="button", id="rlDropdownMenuButton", data-bs-toggle="dropdown", aria-expanded="false">
                <img class="hamburger-vertical-icon"><img>
              </button>
              <ul class="dropdown-menu dropdown-menu-end", aria-labelledby="rlDropdownMenuButton">
                <li>
                  <button class="dropdown-item" ng-click="grid.appScope.ctrl.openEditModal(row.entity)">Edit</button>
                </li>
              </ul></div>`
            }
          ]
        }
        this.getData();
      }

      openEditModal = (redlion) => {
        this.loading = true;
        this.selectedRedLion = JSON.parse(JSON.stringify(redlion));
        this.selectedSite = { id: this.selectedRedLion.SiteId, Name: this.selectedRedLion.Site.Name };
        this.loading = false;
        $("#editModal").modal("show");
      }

      openCreateModal = () => {
        this.selectedRedLion =  {
          id: null,
          Model: null,
          Serial: null,
          DefaultPassword: null,
          CellModel: null,
          CellSerial: null,
          UPC: null,
          IMEI: null,
          IP: null,
          PhoneNumber: null,
          ICCID: null,
          SiteId: null,
          APN: null };
        $("#editModal").modal("show");
      }

      updateRedLion = () => {
        if (this.selectedRedLion.id) {
          this.saveRedLion();
        } else {
          this.createRedLion();
        }
      }

      saveRedLion = () => {
        this.RedLionResource.update({id: this.selectedRedLion.id}, this.selectedRedLion).$promise
        .then(result => {
          if ( result ) {
            toastr.success('RedLion ' + this.selectedRedLion.Serial + ' updated');
            this.selectedRedLion = null;
            $("#editModal").modal("hide");
            this.getRedLions();
          } else {
            toastr.error('Could not update redlion ' + this.selectedRedLion.Serial);
          }
        });
      }

      createRedLion = () => {
        this.RedLionResource.create(this.selectedRedLion).$promise
        .then(result => {
          if ( result ) {
            toastr.success('RedLion ' + this.selectedRedLion.Serial + ' created');
            this.selectedRedLion = null;
            $("#editModal").modal("hide");
            this.getRedLions();
          } else {
            toastr.error('Could not create redlion ' + this.selectedRedLion.Serial);
          }
        });
      }

      setSite = () => {
        this.selectedRedLion.SiteId = this.selectedSite.id;
      }

      getData = () => {
        this.busy = true;
        let selectPromises = [];
        selectPromises.push(this.RedLionResource.list().$promise);
        selectPromises.push(this.SiteResource.listmin().$promise);
        this.$q.all(selectPromises)
          .then(results => {
            if (results[0]) {
              this.gridOptions['redlion'].data = results[0];
            }
            if (results[1]) {
              this.siteList = results[1];
            }
            this.busy = false; 
          });
      }

      getRedLions = () => {
        this.busy = true;
        this.RedLionResource.list().$promise
        .then(result => {
          if (result) {
            this.gridOptions['redlion'].data = result;
          }
          this.busy = false;
        });
      }

    }
    angular.module('gsapp').controller('RedLionController', RedLionController);
  }