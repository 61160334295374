namespace GSapp.controllers {
  'use strict';

  export class EventController extends controllers.BaseController {
    busy: boolean = false;
    loading: boolean = false;
    events: any = null;
    availableSchedules: any = null;
    availableSites: any = null;
    searchText: string = null;
    selectedEvent: any = null;
    selectedSite: any = null;
    eventToEdit: any = {};
    gridOptions: any = {};
    gridApi: any = {};
    
    static readonly $inject = [
      'SiteResource',
      'ScheduleResource',
      'EventResource',
      'uiGridConstants',
      'SystemResource',
      '$window', 
      '$q', '$cookies', 'ReportResource'
    ];

    constructor(
      public SiteResource: interfaces.ISiteResource,
      public ScheduleResource: interfaces.IScheduleResource,
      public EventResource: interfaces.IEventResource,
      public uiGridConstants: any,
      public SystemResource: interfaces.ISystemResource,   
      public $window: ng.IWindowService,
      public $q: ng.IQService,
      public $cookies: any,
      public ReportResource: interfaces.IReportResource ) {
        super(SystemResource, $window, $q, $cookies, ReportResource);
        
        let self = this;

        self.gridOptions['event'] = {
          onRegisterApi: function(gridApi){
            self.gridApi['event'] = gridApi;
          },
          enableFiltering: true,
          enableRowHashing:false,
          enableHorizontalScrollbar: this.uiGridConstants.scrollbars.WHEN_NEEDED,
          rowHeight: this.gridRowHeight,
          data: [],
          columnDefs :[
            { name: 'Site / Group', field: 'Site.Name', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."} },
            { name: 'Start Time', field: 'StartTime', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."},
              cellTemplate: '<div class="ui-grid-cell-contents">{{ COL_FIELD | date:grid.appScope.ctrl.dateFilterFormat:grid.appScope.ctrl.timeZone }}</div>'
            },
            { name: 'Stop Time', field: 'StopTime', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."},
              cellTemplate: '<div class="ui-grid-cell-contents">{{ COL_FIELD | date:grid.appScope.ctrl.dateFilterFormat:grid.appScope.ctrl.timeZone }}</div>'
            },
            { name: 'Start Status', field: 'StartStatus', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."},
              cellTemplate: `<div class="ui-grid-cell-contents">
                <div class="grid grid-cols-2" ng-repeat="unit in row.entity.StartStatusJSON">
                  <div>{{ unit.Unit }}</div>
                  <div>{{ unit.Op }}</div>
                </div>
              </div>`
            },
            { name: 'Stop Status', field: 'StopStatus', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."},
              cellTemplate: `<div class="ui-grid-cell-contents">
                <div class="grid grid-cols-2" ng-repeat="unit in row.entity.StopStatusJSON">
                  <div>{{ unit.Unit }}</div>
                  <div>{{ unit.Op }}</div>
                </div>
              </div>`
            },
            { name: 'Created By', field: 'User.LoginName', filter: { condition: this.userFilter, placeholder: "Comma separated..."},
              cellTemplate: `<div class="ui-grid-cell-contents">
                {{ row.entity.User.FirstName }} {{ row.entity.User.LastName }} ({{ row.entity.User.LoginName }})
                </div>`
            },
            { name: ' ', width: '32', enableFiltering: false, enableSorting: false,
            cellTemplate: `<div class="ui-grid-cell-contents m-0">
              <button class="w-full w-full m-0", type="button", id="eventDropdownMenuButton", data-bs-toggle="dropdown", aria-expanded="false">
                <img class="hamburger-vertical-icon"><img>
              </button>
              <ul class="dropdown-menu dropdown-menu-end", aria-labelledby="eventDropdownMenuButton">
                <li>
                  <button class="dropdown-item" ng-click="grid.appScope.ctrl.openEditEventModal(row.entity)">Edit</button>
                </li>
              </ul></div>`
            }
          ]
        }
        self.getData();

      }

      openEditEventModal = (event) => {
        this.loading = true;
        this.selectedSite = null;
        this.eventToEdit = JSON.parse(JSON.stringify(event));
        this.eventToEdit.StartTime = moment(event.StartTime);
        this.eventToEdit.StopTime = moment(event.StopTime);
        this.openEventModal();
      }

      openCreateEventModal = () => {
        this.loading = true;
        this.selectedSite = null; 
        this.selectedEvent = null;
        let newStartDT = moment().add(5, 'minutes');
        newStartDT.set({second:0, millisecond:0});
        let newStopDT = moment().add(15, 'minutes');
        newStopDT.set({second:0, millisecond:0});
        this.eventToEdit = {
          id: null, 
          SiteId: null,
          ScheduleId: null,
          StartTime: newStartDT,
          StopTime: newStopDT,
        };
        this.openEventModal();
      }

      openEventModal = () => {
        let selectPromises = [];
        selectPromises.push(this.SiteResource.list().$promise);
        selectPromises.push(this.ScheduleResource.findAll().$promise);
        this.$q.all(selectPromises).then((results: any[][]) => {
          if (results[0]) {
            this.availableSites = results[0];
            if(this.eventToEdit.SiteId) {
              this.selectedSite = this.availableSites.find(site => site.id == this.eventToEdit.SiteId);
            }
          }
          if (results[1]) {
            this.availableSchedules = results[1];
          }
          this.loading = false;
          $("#editEventModal").modal("show");
        });
      }

      checkFields = () => {
        let valid = true;
        if(!this.eventToEdit.SiteId) {
          toastr.error('Must select a site');
          valid = false;
        }
        if(!this.eventToEdit.StartTime) {
          toastr.error('Requires a start time.')
          valid = false;
        }
        return valid;
      }

      saveEvent = () => {
        if(this.checkFields()) {
          if (this.eventToEdit.id) {
            this.updateEvent();
          } else {
            this.createEvent();
          }
        }
      }

      updateEvent = () => {
        this.EventResource.update({id: this.eventToEdit.id}, this.eventToEdit).$promise.then(result => {
          if ( result ) {
            toastr.success('Schedule updated.');
            this.eventToEdit = null;
            $("#editEventModal").modal("hide");
            this.getData();
          } else {
            toastr.error('Could not update event.');
          }
        });
      }

      createEvent = () => {
        this.EventResource.create(this.eventToEdit).$promise.then(result => {
          if ( result ) {
            toastr.success('Event created');
            this.eventToEdit = null;
            $("#editEventModal").modal("hide");
            this.getData();
          } else {
            toastr.error('Could not create event.');
          }
        });
      }

      getData = () => {
        this.busy = true;
        this.EventResource.findAll().$promise.then((results) => {
          if (results) {
            for (let Event of results) {
              if (Event.StartStatus) {
                Event['StartStatusJSON'] = JSON.parse(Event.StartStatus);
              }
              if (Event.StopStatus) {
                Event['StopStatusJSON'] = JSON.parse(Event.StopStatus);
              }
            }
            this.gridOptions['event'].data = results;
          }
          this.busy = false;
        });
      };

    }
    angular.module('gsapp').controller('EventController', EventController);
  }