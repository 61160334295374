namespace GSapp.controllers {
  'use strict';

  export class LoginController {
    Login: interfaces.ILogin;
    messages: { error: string, info: string } = { error: null, info: null };
    hasToken: boolean = false;
    confirmedReset: boolean = false;
    qrcode: string = null;
    showMFA: boolean = false;
    strongRegularExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    mediumRegularExp = /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/;
    pwdBackground: string = "bg-danger";
    showHideString: string = "eye-closed";
    showHideResetString: string = "eye-closed";
    newPwdOk: boolean = false;
    newPwdMedium: boolean = false;
    newPwdStrong: boolean = false;
    showPassword: boolean = false;
    showResetPassword: boolean = false;
    loginform: any;
    normalLogin: boolean = true;
    forgotPassword: boolean = false;
    passwordsMatch: boolean = false;
    showResendOTP: boolean = false;

    static readonly $inject = [
      'LoginResource',
      '$window',
      '$timeout',
      '$q', 'ReportResource'
    ];

    constructor(
      public LoginResource: interfaces.ILoginResource,
      public $window: ng.IWindowService,
      public $timeout: ng.ITimeoutService,
      public $q: ng.IQService,
      public ReportResource: interfaces.IReportResource) {
      let self = this;

      let paramsString = this.$window.location.search.replace('?', '');
      let searchParams = new URLSearchParams(paramsString);
      self.hasToken = searchParams.has("token");

      if (self.hasToken) {
        let token = searchParams.get("token");
        //Check the token
        self.LoginResource.checkToken({ token: token }).$promise.then(result => {
          if (result.success) {
            self.confirmedReset = true;
            self.normalLogin = false;
            self.forgotPassword = false;
          } else {
            self.confirmedReset = false;
            self.messages.error = result.message;
          }
        });
      } else {
        this.$timeout(() => {
          let loginNameBox = self.$window.document.getElementById("loginName");
          if (loginNameBox) {
            loginNameBox.focus();
          }
        });
      }
    }
    
    validateEmail = (email) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    resendMFA = () => {
      let self = this;
      this.LoginResource.resendMFA({ LoginName: self.Login.LoginName }).then(result => {
        if (result) {
          toastr.info("MFA Code Sent.  Valid for 10 minutes.");
        }
      });
    }

    forgotPasswordToggle = () => {
      let self = this;
      self.forgotPassword = true;
      self.confirmedReset = false;
      self.normalLogin = false;
    }

    returnToLogin = () => {
      let self = this;
      self.forgotPassword = false;
      self.confirmedReset = false;
      self.normalLogin = true;
    }

    sendReset = () => {
      let self = this;
      if (self.Login) {
        if (self.Login.LoginName) {
          let validEmail = self.validateEmail(self.Login.LoginName);
          if (validEmail) {
            self.LoginResource.sendReset({ LoginName: self.Login.LoginName }).$promise.then(result => {
              if (result.success) {
                self.messages.error = null;
                self.forgotPassword = false;
                toastr.success(result.message);
              } else {
                self.messages.error = result.message;
              }
            }).catch(error => {
              self.messages.error = error.data.message;
            });
          } else {
            self.messages.error = "Not a valid Email.";
          }
        }
      }
    }

    showHidePassword = () => {
      this.showPassword = !this.showPassword;
      this.showHideString = this.showPassword ? "eye-open" : "eye-closed";
    }

    showHideResetPassword = () => {
      this.showResetPassword = !this.showResetPassword;
      this.showHideResetString = this.showResetPassword ? "eye-open" : "eye-closed";
    }

    checkNewPassword = (password) => {
      this.newPwdOk = false;
      this.newPwdMedium = false;
      this.newPwdStrong = false;
      if (this.strongRegularExp.test(password)) {
        this.newPwdOk = true;
        this.newPwdStrong = true;
      } else if (this.mediumRegularExp.test(password)) {
        this.newPwdOk = true;
        this.newPwdMedium = true;
      }
    }

    comparePasswords = (password, newpassword) => {
      this.passwordsMatch = false;
      if (password == newpassword) {
        this.passwordsMatch = true;
      }
    }

    updatePassword = () => {
      let self = this;
      if (self.Login.Password == self.Login.PasswordConfirm) {
        if (this.newPwdOk) {
          let paramsString = this.$window.location.search.replace('?', '');
          let searchParams = new URLSearchParams(paramsString);
          let token = searchParams.get("token");
          self.LoginResource.updatePassword({ token: token, newPassword: self.Login.Password }).$promise.then((result) => {
            self.$window.location.href = "/";
          });
        } else {
          toastr.error("Password is not strong enough.");
        }
      } else {
        toastr.error("Passwords do not match.");
      }
    }

    submit = () => {
      let self = this;
      if (self.Login.LoginName && self.Login.Password) {
        // Check phone number
        self.LoginResource.signin(self.Login).$promise.then(result => {
          if (result.accessToken) {
            self.$window.location.href = "/";
          } else if (result.setupMFA) {
            self.Login.setupMFA = true;
            self.Login.newMFA = result.newMFA;
            self.showMFA = true;
            self.qrcode = result.qrcode;
            self.messages.error = null;
            self.messages.info = null;
          } else if (result.setupMFASMS) {
            self.Login.setupMFASMS = true;
            self.messages.error = null;
            self.messages.info = null;
          } else if (result.showMFA) {
            // Handle autosetup of new MFA for SMS.
            if(result.newMFA) {
              self.Login.newMFA = result.newMFA;
            }
            self.Login.setupMFASMS = undefined;
            self.showMFA = true;
            self.showResendOTP = result.showResendOTP;
            self.messages.error = null;
            self.messages.info = null;
          } else {
            self.messages.info = null;
            toastr.error(result.message);
          }
        });
      }
    }
  }
  angular.module('gsapp').controller('LoginController', LoginController);
}