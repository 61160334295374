namespace GSapp.filters
{
  'use strict';

  export class trueValue
  {
    static filter() {

      return (input : any, status : any) => {
        if(_.isUndefined(input) || _.isNull(input)) {
          return 'n/a';
        }
        let mberr = false;
        if(!_.isUndefined(status) && !_.isNull(status)) {
          if((status & 3) != 1) {
            //If there is a comm failure, return 'MBErr' instead of a number.
            mberr = true;
          }
        }
        if(input == "ON" || input === true || input == "TRUE" || input == 1) {
          return mberr ? '[ON]' : 'ON';
        }
        return mberr ? '[OFF]' : 'OFF';
      };
    }
  }

  angular.module('gsapp').filter('trueValue',  trueValue.filter);
}
