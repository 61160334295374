namespace GSapp.controllers {
  'use strict';

  export class SitesController extends controllers.BaseController {
    busy: boolean = false;
    loading: boolean = false;
    activeEdit: boolean = false;
    siteFilter: string = null;
    userList: any = [];
    customerList: any = [];
    utilityList: any = [];
    supplierList: any = [];
    siteData: any = null;
    gridOptions: any = {};
    gridApi: any = {};
    siteToImport: any = null;
    selectedOwner: any = null;
    selectedEndCustomer: any = null;
    selectedCustomer: any = null;
    selectedUtility: any = null;
    selectedSales: any = null;
    selectedManager: any = null;
    selectedCountry: any = null;
    selectedState: any = null;
    stateList: any = [];
    selectedCity: any = null;
    cityList: any = [];
    selectedFill: any = null;
    selectedSite: any = null;
    selectedSupplier: any = null;
    selectedUnits: any = [];
    selectedUnitsJSON: any = {};
    listStyle: boolean = true;
    oneLineGrid: any = null;
    selectedCellBackup: any;
    selectedCellBackupJSON: any;
    selectedCell: any;
    selectedCellX: number;
    selectedCellY: number;
    isDirtyOneLine: boolean = false;
    unitsMatchOnImport: boolean = true;
    selectedColorAnimation: any = null;
    selectedAnimationType: string = null;
    animationJSON: any = null;
    emailListToEdit = [];
    newEmailAddr: string = null;
    showAddresses: boolean = false;
    showStatus: boolean = true;
    showFuel: boolean = false;
    showWeather: boolean = false;
    reportData: any = null;
    allSites: any = [];

    static readonly $inject = [
      'SiteResource',
      'CustomerResource',
      'UtilityResource',
      'UserResource',
      'FillResource',
      'SupplierResource',
      'uiGridConstants',
      '$sce',
      'SystemResource',
      '$window', 
      '$q', '$cookies', 'ReportResource'
    ];

    constructor(
      public SiteResource: interfaces.ISiteResource,
      public CustomerResource: interfaces.ICustomerResource,
      public UtilityResource: interfaces.IUtilityResource,
      public UserResource: interfaces.IUserResource,
      public FillResource: interfaces.IFillResource,
      public SupplierResource: interfaces.ISupplierResource,
      public uiGridConstants: any,
      public $sce: ng.ISCEService,
      public SystemResource: interfaces.ISystemResource,   
      public $window: ng.IWindowService,
      public $q: ng.IQService,
      public $cookies: any,
      public ReportResource: interfaces.IReportResource 
      ) {
        super(SystemResource, $window, $q, $cookies, ReportResource);

        let self = this;
        
        self.gridOptions['site'] = {
          onRegisterApi: function(gridApi){
            self.gridApi['site'] = gridApi;
          },
          enableFiltering: true,
          rowHeight: this.grid2RowHeight,
          enableHorizontalScrollbar: this.uiGridConstants.scrollbars.WHEN_NEEDED,
          data: [],
          columnDefs :[
            { name: 'Site', field: 'Name', minWidth: '268', enableFiltering: false,
              cellTemplate: `<div class="ui-grid-cell-contents gap-2 flex">
                <div ng-bind-html="row.entity.donut"></div>
                <div class="flex flex-col w-full">
                  <div class="flex flex-row w-full gap-1">
                    <a ng-hide="row.entity.ExternalViewLink != null" ng-href="/site/{{row.entity.id}}", target="_blank" title={{row.entity.Notes}}><img class="arrow-fwd pl-2"></img></a>
                    <a ng-show="row.entity.ExternalViewLink != null" ng-href="{{row.entity.ExternalViewLink}}" title='Go To Local Site'><img class="arrow-fwd pl-2"></img></a>
                    <span class="font-bold">{{ COL_FIELD }}</span>
                  </div>
                  <div class="px-2" ng-bind-html="row.entity.siteStatus"></div>
                  <span class="px-2 text-[10px]">Updated: {{ row.entity.iodata.timestamp | date:grid.appScope.ctrl.dateFilterFormat:grid.appScope.ctrl.timeZone }}</span>
                </div>
              </div>`
            },
            { name: 'Street', field: 'Address1', enableFiltering: false, visible: false,
              cellTemplate: `<div class="ui-grid-cell-contents flex justify-left">
                <span class='self-center text-wrap'>{{ COL_FIELD }}</span>
              </div>` 
            },
            { name: 'City', field: 'Locality', enableFiltering: false, visible: false,
              cellTemplate: `<div class="ui-grid-cell-contents flex justify-left">
                <span class='self-center text-wrap'>{{ COL_FIELD }}</span>
              </div>` 
            },
            { name: 'State', field: 'Region', width:'168', enableFiltering: false, visible: false ,
              cellTemplate: `<div class="ui-grid-cell-contents flex justify-left">
                <span class='self-center text-wrap'>{{ COL_FIELD }}</span>
              </div>` 
            },
            { name: 'Zip', field: 'Postcode', width:'98', enableFiltering: false, visible: false,
              cellTemplate: `<div class="ui-grid-cell-contents flex justify-left">
                <span class='self-center text-wrap'>{{ COL_FIELD }}</span>
              </div>` 
            },
            { name: 'Country', field: 'Country', width:'178', enableFiltering: false, visible: false,
              cellTemplate: `<div class="ui-grid-cell-contents flex justify-left">
                <span class='self-center text-wrap'>{{ COL_FIELD }}</span>
              </div>` 
            },
            { name: 'Latitude', field: 'iodata.location.lat', width:'138', enableFiltering: false, visible: false,
              cellTemplate: `<div class="ui-grid-cell-contents flex justify-center">
                <span class='self-center text-wrap'>{{ COL_FIELD | formatLocation:row.entity.iodata.location.valid }}</span>
              </div>`
            },
            { name: 'Longitude', field: 'iodata.location.long', width:'138', enableFiltering: false, visible: false,
              cellTemplate: `<div class="ui-grid-cell-contents flex justify-center">
                <span class='self-center text-wrap'>{{ COL_FIELD | formatLocation:row.entity.iodata.location.valid }}</span>
              </div>`
            },
            { name: 'Status', field: 'iodata.tags', width: '248', enableFiltering: false,
              cellTemplate: `<div class="ui-grid-cell-contents overflow-y-auto flex flex-col">
                <div class="flex justify-center gap-2 pt-3 bg-gs-unknown relative">
                  <div class="top-left-label">Site</div>
                  <span class='self-center text-wrap'>{{ COL_FIELD.status | statusValue }}</span>
                  <span class='self-center text-wrap'>{{ COL_FIELD.opStatus | statusValue }}</span>
                </div>
                <div class="self-stretch flex flex-col gap-1 border-1 border-white"  ng-repeat="(unitType, data) in COL_FIELD">
                  <div class="flex flex-wrap justify-center pt-3 relative" ng-class="{ 
                    'bg-gs-unknown': (data.status == undefined || data.status == null),
                    'bg-gs-warn-fade': (data.status.indexOf('OFF') > -1), 
                    'bg-gs-success-fade': ((data.status.indexOf('STOP') > -1) || (data.status.indexOf('AUTO') > -1)), 
                    'bg-gs-danger-fade': (data.status.indexOf('FAULT') > -1),
                    }" ng-if="data.hasOwnProperty('status') && !['G','U','I'].includes(unitType)">
                    <div class="top-left-label">{{ grid.appScope.ctrl.getUnitTypeName(unitType) }}</div>
                    <span class="text-wrap">{{ data.status | statusValue }}</span>
                  </div>
                </div>
              </div>`
            },
            { name: 'UtilInfo', field: 'iodata.tags.U', displayName: 'Utility | kW', width: '248', enableFiltering: false,
              cellTemplate: `<div class="ui-grid-cell-contents h-full flex flex-col overflow-y-auto">
                <div class="self-stretch flex flex-col gap-1 border-1 border-white">
                  <div class="flex flex-wrap justify-center relative" ng-class="{ 
                    'bg-gs-unknown': (COL_FIELD.status == undefined || COL_FIELD.status == null),
                    'bg-gs-warn-fade': (COL_FIELD.status.indexOf('OFF') > -1), 
                    'bg-gs-success-fade': ((COL_FIELD.status.indexOf('STOP') > -1) || (COL_FIELD.status.indexOf('AUTO') > -1)), 
                    'bg-gs-danger-fade': (COL_FIELD.status.indexOf('FAULT') > -1),
                    }" ng-if="COL_FIELD.hasOwnProperty('status')">
                    <span class="text-wrap">{{ COL_FIELD.status | statusValue }}</span>
                  </div>
                </div>
                <div ng-repeat="(key, unit) in COL_FIELD" class="w-full h-full max-h-12 flex flex-row justify-between" ng-if="unit.hasOwnProperty('kw')">
                  <div class="ui-grid-cell-table-th">
                    {{ key }}
                  </div>
                  <div class="ui-grid-cell-table-td">
                    {{ unit.kw | formatValue:1:65530:unit.comms }}
                  </div>
                </div>
              </div>`
            },
            { name: 'GenInfo', field: 'fuelAlarms', displayName: 'Gen Unit | kW | RPM | Batt | Fuel | DEF', enableFiltering: false,
              cellTemplate: `<div class="ui-grid-cell-contents h-full flex flex-col overflow-y-auto">
                <div class="self-stretch flex flex-col gap-1 border-1 border-white">
                  <div class="flex flex-wrap justify-center relative" ng-class="{ 
                    'bg-gs-unknown': (row.entity.iodata.tags.G.status == undefined || row.entity.iodata.tags.G.status == null),
                    'bg-gs-warn-fade': (row.entity.iodata.tags.G.status.indexOf('OFF') > -1), 
                    'bg-gs-success-fade': ((row.entity.iodata.tags.G.status.indexOf('STOP') > -1) || (row.entity.iodata.tags.G.status.indexOf('AUTO') > -1)), 
                    'bg-gs-danger-fade': (row.entity.iodata.tags.G.status.indexOf('FAULT') > -1),
                    }" ng-if="row.entity.iodata.tags.G.hasOwnProperty('status')">
                    <span class="text-wrap">{{ row.entity.iodata.tags.G.status | statusValue }}</span>
                  </div>
                </div>
                <div ng-repeat="(key, unit) in row.entity.iodata.tags.G" class="w-full h-full max-h-12 flex flex-row justify-between" ng-if="unit.hasOwnProperty('rpm')">
                  <div class="ui-grid-cell-table-th">
                    {{ key }}
                  </div>
                  <div class="ui-grid-cell-table-td">
                    {{ unit.kw | formatValue:1:65530:unit.comms }}
                  </div>
                  <div class="ui-grid-cell-table-td">
                    {{ unit.rpm | formatValue:1:65530:unit.comms }}
                  </div>
                  <div class="ui-grid-cell-table-td">
                    {{ unit.batt | formatValue:1:65530:unit.comms }}
                  </div>
                  <div class="ui-grid-cell-table-td">
                    {{ unit.dlvl | formatValue:1:65530:unit.comms }}%
                  </div>
                  <div class="ui-grid-cell-table-td">
                    {{ unit.deflvl | formatValue:1:65530:unit.comms }}%
                  </div>
                </div>
              </div>`,
              filters: [
                {
                  placeholder: '...Filter Running',
                  type: uiGridConstants.filter.SELECT,
                  selectOptions: [ { value: 'S', label: 'Stopped' }, { value: 'R', label: 'Running' } ]
                },
                {
                  placeholder: '...Filter Fuel',
                  type: uiGridConstants.filter.SELECT,
                  selectOptions: [ { value: 'LF', label: 'Low Fuel' }, { value: 'CF', label: 'Crit Low Fuel' }, { value: 'LD', label: 'Low DEF' }, { value: 'CD', label: 'Crit Low DEF' } ]
                }
              ]
            },
            { name: 'InvInfo', field: 'iodata.tags.I', displayName: 'Inverter Unit | kW', width: '248', enableFiltering: false,
              cellTemplate: `<div class="ui-grid-cell-contents h-full flex flex-col overflow-y-auto">
                <div class="self-stretch flex flex-col gap-1 border-1 border-white">
                  <div class="flex flex-wrap justify-center relative" ng-class="{ 
                    'bg-gs-unknown': (COL_FIELD.status == undefined || COL_FIELD.status == null),
                    'bg-gs-warn-fade': (COL_FIELD.status.indexOf('OFF') > -1), 
                    'bg-gs-success-fade': ((COL_FIELD.status.indexOf('STOP') > -1) || (COL_FIELD.status.indexOf('AUTO') > -1)), 
                    'bg-gs-danger-fade': (COL_FIELD.status.indexOf('FAULT') > -1),
                    }" ng-if="COL_FIELD.hasOwnProperty('status')">
                    <span class="text-wrap">{{ COL_FIELD.status | statusValue }}</span>
                  </div>
                </div>
                <div ng-repeat="(key, unit) in row.entity.iodata.tags.I" class="w-full h-full max-h-12 flex flex-row justify-between" ng-if="unit.hasOwnProperty('kw')">
                  <div class="ui-grid-cell-table-th">
                    {{ key }}
                  </div>
                  <div class="ui-grid-cell-table-td">
                    {{ unit.kw | formatValue:1:65530:unit.comms }}
                  </div>
                </div>
              </div>`
            },
            { name: 'Live', field: 'Live',width: '86', visible: false, enableFiltering: false,
              cellTemplate: `<div class="ui-grid-cell-contents flex justify-center">
                  <img class="circle-check-icon" ng-if="COL_FIELD"></img>
                </div>`,
              filter: {
                condition: uiGridConstants.filter.CONTAINS,
                type: uiGridConstants.filter.SELECT,
                selectOptions: [ {value: null, label: 'All'}, { value: true, label: 'Live' }, { value: false, label: 'Offline'} ]
              }
            },
            { name: 'Controls', field: 'ControlsDisabled', width: '86', visible: false, enableFiltering: false,
              cellTemplate: `<div class="ui-grid-cell-contents flex justify-center">
                <img class="circle-check-icon" ng-if="COL_FIELD"></img></div>`,
              filter: {
                type: uiGridConstants.filter.SELECT,
                selectOptions: [ {value: null, label: 'All'}, { value: true, label: 'No Control' }, { value: false, label: 'Control'} ]
              }
            },
            { name: 'Fueling', field: 'openOrder', displayName: 'Fueling Filled / Ordered', width:'798', enableFiltering: false, visible: false,
              cellTemplate: `<div class="ui-grid-cell-contents h-full flex flex-col overflow-y-auto">
                    <div class="flex flex-col" ng-repeat="supplier in row.entity.Suppliers" class="w-full h-full flex flex-col justify-between">
                      <div class="flex flex-row w-full">
                        <div class="ui-grid-cell-table-th">{{ supplier.SiteSupplier.Default ? 'Default ' :'' }}Fuel Supplier</div>
                        <div class="ui-grid-cell-table-td">{{ supplier.Name }}</div>
                      </div>
                      <div class="flex flex-row">
                        <div class="ui-grid-cell-table-td">{{ supplier.ContactName }}</div>
                        <div class="ui-grid-cell-table-td">{{ supplier.ContactEmail }}</div>
                        <div class="ui-grid-cell-table-td">{{ supplier.ContactPhone }}</div>
                      </div>
                    </div>
                  <div ng-repeat="fill in row.entity.Fills" class="w-full h-10 flex flex-row justify-between">
                    <div class="ui-grid-cell-table-th gap-2">
                      <span ng-if="fill.FillDate">Filled:</span>
                      <span ng-if="!fill.FillDate">Ordered:</span>
                    </div>
                    <div class="ui-grid-cell-table-td">
                      {{ (fill.FillDate ? fill.FillDate : fill.OrderDate) | date:grid.appScope.ctrl.dateOnlyFilterFormat:grid.appScope.ctrl.timeZone }}
                    </div>
                    <div class="ui-grid-cell-table-th gap-2">
                      <span>Diesel:</span>
                    </div>
                    <div class="ui-grid-cell-table-td">
                      {{ (fill.FillGalDIESEL ? grid.appScope.ctrl.volumeConvert(fill.FillGalDIESEL) : '-') | formatValue:0 }} / {{ grid.appScope.ctrl.volumeConvert(fill.OrderedGalDIESEL) | formatValue:0 }} {{grid.appScope.ctrl.units === 'imperial' ? 'gal' : 'L'}}
                    </div>
                    <div class="ui-grid-cell-table-th gap-2">
                      <span>DEF:</span>
                    </div>
                    <div class="ui-grid-cell-table-td">
                      {{ (fill.FillGalDEF ? grid.appScope.ctrl.volumeConvert(fill.FillGalDEF) : '-') | formatValue:0 }} / {{ grid.appScope.ctrl.volumeConvert(fill.OrderedGalDEF) | formatValue:0 }} {{grid.appScope.ctrl.units === 'imperial' ? 'gal' : 'L'}}
                    </div>
                    <div class="ui-grid-cell-table-th gap-2" ng-if="fill.Supplier.Propane">
                      <span>Propane:</span>
                    </div>
                    <div class="ui-grid-cell-table-td" ng-if="fill.Supplier.Propane">
                      {{ (fill.FillGalPROP ? grid.appScope.ctrl.volumeConvert(fill.FillGalPROP) : '-') | formatValue:0 }} / {{ grid.appScope.ctrl.volumeConvert(fill.OrderedGalPROP) | formatValue:0 }} {{grid.appScope.ctrl.units === 'imperial' ? 'gal' : 'L'}}
                    </div>
                    <div class="w-36 ui-grid-cell-table-th">
                      <button ng-if="!fill.FillDate" ng-click="grid.appScope.ctrl.openDeliverFill(row.entity, fill)">
                        <img class="truck-icon"></img>
                      </button>
                      <button ng-if="fill.FillDate" ng-click="grid.appScope.ctrl.openEditFill(row.entity, fill)"
                        <img class="edit-icon"></img>
                      </button>
                    </div>
                  </div>
                </div>`
            },
            { name: 'Temperature', field: 'WeatherJSON.main', width: '268', displayName: 'Temperature | Min | Max', enableFiltering: false, visible: false,
              cellTemplate: `<div class="ui-grid-cell-contents flex">
                <div class="self-center grid grid-cols-3 w-full" ng-if="COL_FIELD">
                  <div class="self-center flex flex-row gap-2">
                    <i class="fa-solid fa-temperature-half"></i>
                    <span class="origin-center">{{grid.appScope.ctrl.tempConvert(COL_FIELD.temp)}}{{grid.appScope.ctrl.units === 'imperial' ? '&#8457;' : '&#8451'}}</span>
                  </div>
                  <div class="self-center flex flex-row gap-2">
                    <i class="fa-solid fa-temperature-low"></i>
                    <span class="origin-center">{{grid.appScope.ctrl.tempConvert(COL_FIELD.temp_min)}}{{grid.appScope.ctrl.units === 'imperial' ? '&#8457;' : '&#8451'}}</span>
                  </div>
                  <div class="self-center flex flex-row gap-2">
                    <i class="fa-solid fa-temperature-high"></i>
                    <span class="origin-center">{{grid.appScope.ctrl.tempConvert(COL_FIELD.temp_max)}}{{grid.appScope.ctrl.units === 'imperial' ? '&#8457;' : '&#8451'}}</span>
                  </div>
                </div>
              </div>`
            },
            { name: 'Humidity', field: 'WeatherJSON.main', displayName: 'Pressure | Humidity', enableFiltering: false, visible: false,
              cellTemplate: `<div class="ui-grid-cell-contents flex">
                <div class="self-center grid grid-cols-2 w-full" ng-if="COL_FIELD">
                  <div class="self-center flex flex-row gap-2">
                    <i class="fa-solid fa-gauge-simple-high"></i>
                    <span class="origin-center">{{grid.appScope.ctrl.pressureConvert(COL_FIELD.pressure)}} {{grid.appScope.ctrl.units === 'imperial' ? 'psi' : 'hPa'}}</span>
                  </div>
                  <div class="self-center flex flex-row gap-2">
                    <i class="fa-solid fa-droplet"></i>
                    <span class="origin-center">{{COL_FIELD.humidity}}%</span>
                  </div>
                </div>
              </div>`
            },
            { name: 'Weather', field: 'WeatherJSON.weather[0]', displayName: 'Weather', enableFiltering: false, visible: false,
              cellTemplate: `<div class="ui-grid-cell-contents flex">
                <div class="self-center grid grid-cols-2 w-full" ng-if="COL_FIELD">
                  <div class="self-center flex flex-row gap-2">
                    <img class="h-10" ng-class="'w' + COL_FIELD.icon"></img>
                    <span class="self-center">{{COL_FIELD.main}}</span>
                  </div>
                  <div class="self-center flex flex-row">
                    <span class="origin-center">{{COL_FIELD.description}}</span>
                  </div>
                </div>
              </div>`
            },
            { name: 'Wind', field: 'WeatherJSON.wind', width: '128', displayName: 'Wind', enableFiltering: false, visible: false,
              cellTemplate: `<div class="ui-grid-cell-contents flex">
                <div class="self-center grid grid-cols-2 w-full" ng-if="COL_FIELD">
                  <div class="self-center flex flex-row gap-2">
                    <i class="fa-solid fa-wind"></i>
                    <i class="fa-solid fa-up-long fa-rotate-by" style="--fa-rotate-angle: {{COL_FIELD.deg}}deg;"></i>
                    <span class="origin-center">{{grid.appScope.ctrl.speedConvert(COL_FIELD.speed)}}{{grid.appScope.ctrl.units === 'imperial' ? 'mph' : 'km/h'}}</span>
                  </div>
                </div>
              </div>`
            },
            { name: 'Daylight', field: 'WeatherJSON.sys', width: '168', displayName: 'Sunrise | Sunset', enableFiltering: false, visible: false,
              cellTemplate: `<div class="ui-grid-cell-contents ">
                <div class="self-center grid grid-cols-4 w-full" ng-if="COL_FIELD">
                  <div class="ui-grid-cell-table-th">
                    <img class="h-5 w01d"></img>
                  </div>
                  <div class="ui-grid-cell-table-td col-span-3">
                    <span class="origin-center">{{ COL_FIELD.sunriseTS | date:grid.appScope.ctrl.timeOnlyFilterFormat:grid.appScope.ctrl.timeZone }}</span>
                  </div>
                  <div class="ui-grid-cell-table-th">
                    <img class="h-5 w01n"></img>
                  </div>
                  <div class="ui-grid-cell-table-td col-span-3">
                    <span class="origin-center">{{ COL_FIELD.sunsetTS | date:grid.appScope.ctrl.timeOnlyFilterFormat:grid.appScope.ctrl.timeZone }}</span>
                  </div>
                </div>
              </div>`
            },
            { name: 'Command', displayName: '', width: '32', enableColumnMenu: false, enableFiltering: false, enableSorting: false, 
              cellTemplate: `<div class="ui-grid-cell-contents m-0">
                <button class="w-full w-full m-0", type="button", id="siteDropdownMenuButton", data-bs-toggle="dropdown", aria-expanded="false">
                  <img class="hamburger-vertical-icon"><img>
                </button>
                <ul class="dropdown-menu dropdown-menu-end", aria-labelledby="siteDropdownMenuButton">
                  <li>
                    <button class="dropdown-item", ng-if="!grid.appScope.ctrl.showFuel", ng-if="row.entity.edit", ng-click="grid.appScope.ctrl.openEditModal(row.entity)">Edit Site</button>
                  </li>
                  <li>
                    <button class="dropdown-item", ng-if="!grid.appScope.ctrl.showFuel", ng-if="row.entity.edit", ng-click="grid.appScope.ctrl.openOneLineEditModal(row.entity)">Edit OneLine</button>
                  </li>
                  <li>
                    <button class="dropdown-item", ng-if="!grid.appScope.ctrl.showFuel", ng-if="row.entity.edit", ng-click="grid.appScope.ctrl.openEditCommands(row.entity)">Edit Commands</button>
                  </li>
                  <li>
                    <button class="dropdown-item", ng-if="!grid.appScope.ctrl.showFuel", ng-if="row.entity.edit", ng-click="grid.appScope.ctrl.editEmailList(row.entity)">Edit Notification List</button>
                  </li>
                  <li>
                    <a class="dropdown-item", ng-href="/site/{{row.entity.id}}", target="_blank">Open Site Detail</a>
                  </li>
                  <li>
                    <button class="dropdown-item", ng-if="grid.appScope.ctrl.showFuel", ng-click="grid.appScope.ctrl.openCreateFill(row.entity)">Order Fuel</button>
                  </li>
                  <li ng-if="row.entity.availableCommands['cmds'].length > 0"><hr class="dropdown-divider"></li>
                  <li ng-repeat="cmd in row.entity.availableCommands['cmds']", ng-if="!grid.appScope.ctrl.showFuel">
                    <button class="dropdown-item", ng-click="grid.appScope.ctrl.sendCommand(row.entity.id, cmd.cmd)">{{ cmd.desc }}</button>
                  </li>
                </ul></div>`}
          ]
        }
        self.getData();
        angular.element(function () {
          self.toggleColumns(self.$cookies.get('sitesTab') || 'Status');
          self.applyFilters(false);
        });
        setInterval( () => { self.getData() }, 5000);
      }

      getSiteUrl = (siteUrl) => {
        return this.$sce.trustAsResourceUrl(siteUrl);
      }

      toggleColumns = (showPage: string) => {
        this.$cookies.put('sitesTab', showPage);
        const showStatus = (showPage === 'Status');
        const showAddress = (showPage === 'Location');
        const showFuel = (showPage === 'Fuel');
        const showWeather = (showPage === 'Weather');

        this.gridOptions['site'].rowHeight = this.grid2RowHeight;
        if(showPage === 'Fuel') {
          this.gridOptions['site'].rowHeight = this.grid3RowHeight;
        }
        if(showPage === 'Status') {
          this.gridOptions['site'].rowHeight = this.grid3RowHeight;
        }
        // Show Location Columns
        this.gridOptions['site'].columnDefs[1].visible = showAddress;
        this.gridOptions['site'].columnDefs[2].visible = showAddress;
        this.gridOptions['site'].columnDefs[3].visible = showAddress;
        this.gridOptions['site'].columnDefs[4].visible = showAddress;
        this.gridOptions['site'].columnDefs[5].visible = showAddress;
        this.gridOptions['site'].columnDefs[6].visible = showAddress;
        this.gridOptions['site'].columnDefs[7].visible = showAddress;

        // Show Status Columns
        this.gridOptions['site'].columnDefs[8].visible = showStatus;
        this.gridOptions['site'].columnDefs[9].visible = showStatus;
        this.gridOptions['site'].columnDefs[10].visible = showStatus || showFuel;
        this.gridOptions['site'].columnDefs[11].visible = showStatus;

        // Show Fuel Columns
        this.gridOptions['site'].columnDefs[14].visible = showFuel;

        // Show Weather Columns
        this.gridOptions['site'].columnDefs[15].visible = showWeather;
        this.gridOptions['site'].columnDefs[16].visible = showWeather;
        this.gridOptions['site'].columnDefs[17].visible = showWeather;
        this.gridOptions['site'].columnDefs[18].visible = showWeather;
        this.gridOptions['site'].columnDefs[19].visible = showWeather;

        this.showAddresses = showAddress;
        this.showFuel = showFuel;
        this.showStatus = showStatus;
        this.showWeather = showWeather;
        this.gridApi['site'].grid.refresh();
      }

      openEditModal = (site) => {
        this.loading = true;
        this.activeEdit = true;
        this.siteData = JSON.parse(JSON.stringify(site));
        this.siteData['dates'] = {
          LiveDate: new Date(this.siteData.LiveDate),
          NotMonitoredDate: this.siteData.NotMonitoredDate ? new Date(this.siteData.NotMonitoredDate) : null,
          ClosedDate: this.siteData.ClosedDate ? new Date(this.siteData.ClosedDate) : null,
          ControlsDisabledDate: this.siteData.ControlsDisabledDate ? new Date(this.siteData.ControlsDisabledDate) : null
        }
        this.openModal();
      }

      openCreateModal = () => {
        this.loading = true;
        this.activeEdit = true;
        this.selectedCountry = null;
        this.selectedState = null;
        this.selectedCity = null;
        this.siteData = {
          id: null, 
          Name: null, 
          IoTHub: 'mqtts://mqtt.gridsure.com',
          Type: 'MOBILE',
          MonitoringContract: false,
          ServiceContract: false,
          Live: true,
          NotMonitored: false,
          ControlsDisabled: false,
          FuelManagement: false,
          DEFManagement: false
        };
        this.openModal();
      }
    
      openOneLineEditModal = (site) => {
        let self = this;
        self.activeEdit = true;
        self.siteToImport = null;
        self.clearSelected();
        self.siteData = JSON.parse(JSON.stringify(site));
        self.SiteResource.getSiteOneLine({id: site.id }).$promise.then((result) => {
          if(result['OneLine']) {
            self.oneLineGrid = JSON.parse(result['OneLine']);
          } else {
            self.oneLineGrid = [];
          }
          $("#editOneLineModal").modal("show");
        });
      }

      importOneLineFrom = () => {
        let self = this;
        self.SiteResource.getSiteOneLine({ id: this.siteToImport }).$promise.then((result) => {
          if(result['OneLine']) {
            self.oneLineGrid = JSON.parse(result['OneLine']);
          } else {
            toastr.error('No OneLine available.');
          }
          self.siteToImport = null;
          self.clearSelected();
        });
      }

      openCreateFill = (site) => {
        this.activeEdit = true;
        let selectPromises = [];
        selectPromises.push(this.SupplierResource.list().$promise);
        this.$q.all(selectPromises).then((results: any[][]) => {
          if (results[0]) {
            this.supplierList = results[0];
            this.selectedSupplier = null;
            this.selectedUnits = [];
            this.selectedUnitsJSON = {};
            this.selectedSite = JSON.parse(JSON.stringify(site));
            this.selectedFill = {
              id: null,
              SiteId: this.selectedSite.id,
              Units : null,
              SupplierId: null,
              OrderedGalDIESEL: null,
              OrderedGalDEF: null,
              OrderedGalPROP: null,
              FillGalDIESEL: null,
              FillGalDEF: null,
              FillGalPROP: null,
              PriceDIESEL: null,
              PriceDEF: null,
              PricePROP: null,
              TaxDIESEL: null,
              TaxDEF: null,
              TaxPROP: null,
              OrderDate: moment(),
              ScheduleDate: moment().add(1, 'days'),
              On: true,
              FillDate: null,
              Cancelled: false,
              Deleted: false,
              Notes: null,
              OrderedBy: null,
              UpdatedBy: null
            }
            $("#editFillModal").modal("show");
          }
        });
      }

      openEditFill = (site, fill) => {
        this.activeEdit = true;
        let selectPromises = [];
        selectPromises.push(this.SupplierResource.list().$promise);
        this.$q.all(selectPromises).then((results: any[][]) => {
          if (results[0]) {
            this.supplierList = results[0];
            this.selectedSite = JSON.parse(JSON.stringify(site));
            this.selectedFill = JSON.parse(JSON.stringify(fill));
            this.selectedUnitsJSON = JSON.parse(this.selectedFill.Units);
            this.selectedSupplier = this.selectedFill.Supplier;
            this.selectedUnits = [];
            this.selectedFill.OrderDate = this.selectedFill.OrderDate ? moment(this.selectedFill.OrderDate) : null;
            this.selectedFill.ScheduleDate = this.selectedFill.ScheduleDate ? moment(this.selectedFill.ScheduleDate) : null;
            this.selectedFill.FillDate = this.selectedFill.FillDate ? moment(this.selectedFill.FillDate) : null;
            for(let unitCode in this.selectedUnitsJSON) {
              this.selectedUnits.push(unitCode);
            }
            $("#editFillModal").modal("show");
          }
        });
      }

      openDeliverFill = (site, fill) => {
        this.activeEdit = true;
        let selectPromises = [];
        selectPromises.push(this.SupplierResource.list().$promise);
        this.$q.all(selectPromises).then((results: any[][]) => {
          if (results[0]) {
            this.supplierList = results[0];
            this.selectedSite = JSON.parse(JSON.stringify(site));
            this.selectedFill = JSON.parse(JSON.stringify(fill));
            this.selectedUnitsJSON = JSON.parse(this.selectedFill.Units);
            this.selectedSupplier = this.selectedFill.Supplier;
            this.selectedUnits = [];
            this.selectedFill.OrderDate = this.selectedFill.OrderDate ? moment(this.selectedFill.OrderDate) : null;
            this.selectedFill.ScheduleDate = this.selectedFill.ScheduleDate ? moment(this.selectedFill.ScheduleDate) : null;
            this.selectedFill.FillDate = this.selectedFill.FillDate ? moment(this.selectedFill.FillDate) : null;
            for(let unitCode in this.selectedUnitsJSON) {
              this.selectedUnits.push(unitCode);
            }
            $("#editFillModal").modal("show");
          }
        });
      }

      saveFill = () => {
        this.selectedFill.Units = JSON.stringify(this.selectedUnitsJSON);
        this.selectedFill.OrderDate = this.selectedFill.OrderDate ? moment(this.selectedFill.OrderDate).toDate() : null;
        this.selectedFill.ScheduleDate = this.selectedFill.ScheduleDate ? moment(this.selectedFill.ScheduleDate).toDate() : null;
        this.selectedFill.FillDate = this.selectedFill.FillDate ? moment(this.selectedFill.FillDate).toDate() : null;

        if (this.selectedFill.id) {
          this.updateFill();
        } else {
          this.createFill();
        }
      }

      createFill = () => {
        this.FillResource.create(this.selectedFill).$promise.then(result => {
          if ( result ) {
            toastr.success('Fill created');
            this.selectedFill = null;
            this.activeEdit = false;
            $("#editFillModal").modal("hide");
            this.getData();
          } else {
            toastr.error('Could not create fill');
          }
        });
      }
      
      updateFill = () => {
        this.FillResource.update({ id: this.selectedFill.id }, this.selectedFill).$promise.then(result => {
          if(result.success === true) {
            toastr.success("Saved Fill");
            this.activeEdit = false;
            $("#editFillModal").modal("hide");
          } else {
            toastr.error(result.message);
          }
        });
      }

      setUnits = () => {
        let tempUnits = {};
        this.selectedUnits.forEach(selectedUnit => {
          if(!this.selectedUnitsJSON.hasOwnProperty(selectedUnit)) {
            this.selectedUnitsJSON[selectedUnit] = {
              UnitId: selectedUnit,
              FuelLevel: { Ordered: this.selectedSite.iodata.tags['G'][selectedUnit].dlvl, Fill: null },
              DEFLevel: { Ordered: this.selectedSite.iodata.tags['G'][selectedUnit].deflvl, Fill: null },
              RunHours: { Ordered: this.selectedSite.iodata.tags['G'][selectedUnit].hrs, Fill: null },
              GalFilled: { DIESEL: null, DEF: null, PROP: null }
            }
          }
        });
        for (let unitCode of this.selectedUnits) {
          tempUnits[unitCode] = JSON.parse(JSON.stringify(this.selectedUnitsJSON[unitCode]));
        }
        this.selectedUnitsJSON = JSON.parse(JSON.stringify(tempUnits));
      }

      openEditCommands = (site) => {
        this.activeEdit = true;
        this.siteData = JSON.parse(JSON.stringify(site));
        if(!this.siteData.availableCommands) {
          this.siteData['availableCommands'] = {}; 
          this.siteData['availableCommands']['cmds'] = [];
          for(let key in this.siteData.iodata.tags) {
            if(typeof this.siteData.iodata.tags[key] == 'object') {
              if (typeof this.siteData.iodata.tags[key]['cmd'] == 'string') {
                this.siteData['availableCommands'][key] = {};
                this.siteData['availableCommands'][key]['name'] = null;
                this.siteData['availableCommands'][key]['cmds'] = [];
              }
              for(let keysub in this.siteData.iodata.tags[key]) {
                if(typeof this.siteData.iodata.tags[key][keysub] == 'object') {
                  if (typeof this.siteData.iodata.tags[key][keysub]['cmd'] == 'string') {
                    if(typeof this.siteData['availableCommands'][key] == 'undefined') {
                      this.siteData['availableCommands'][key] = {};
                    }
                    this.siteData['availableCommands'][key][keysub] = {};
                    this.siteData['availableCommands'][key][keysub]['name'] = this.siteData.iodata.tags[key][keysub]['name'];
                    this.siteData['availableCommands'][key][keysub]['cmds'] = [];
                  }
                }
              }
            }
          }
        }
        $("#editCommandModal").modal("show");
      }

      addCommand = (obj) => {
        if(!obj['cmds']) {
          obj['cmds'] = [];
        }
        obj.cmds.push({cmd:null, desc: null});
      }

      sendCommand = (siteId: number, cmd: string ) => {
        let newCommand = {
          SiteId: siteId,
          UnitCode: null,
          Cmd: cmd
        }
        this.SiteResource.sendCommand(newCommand).$promise.then(result => {
          if(result.success) {
            toastr.success("Command sent.");
          } else {
            toastr.error("Failed to send command: " + result.message);
          }
        });
      }

      saveCommand = () => {
        this.SiteResource.updateSiteCommands({ SiteId: this.siteData.id, AvailableCommands: angular.toJson(this.siteData.availableCommands)}).$promise.then(result => {
          if(result.success === true) {
            toastr.success("Saved Commands");
            this.activeEdit = false;
            $("#editCommandModal").modal("hide");
          } else {
            toastr.error(result.message);
          }
        });
      }

      openModal = () => {
        let selectPromises = [];
        selectPromises.push(this.UserResource.list().$promise);
        selectPromises.push(this.CustomerResource.list().$promise);
        selectPromises.push(this.UtilityResource.list().$promise);
        this.$q.all(selectPromises).then((results: any[][]) => {
          if (results[0]) {
            this.userList = results[0];
            this.selectedSales = this.userList.find(user => user.id == this.siteData.SalesPersonId);
            this.selectedManager = this.userList.find(user => user.id == this.siteData.ReportManagerId);
          }
          if (results[1]) {
            this.customerList = results[1];
            this.selectedCustomer = this.customerList.find(customer => customer.id == this.siteData.CustomerId);
          }
          if (results[2]) {
            this.utilityList = results[2];
            this.selectedUtility = this.utilityList.find(utility => utility.id == this.siteData.UtilityCompanyId);
          }
          this.selectedCountry = this.countryList.find((country) => country.name == this.siteData.Country);

          if(this.selectedCountry) {
            this.SystemResource.liststate({id: this.selectedCountry.id}).$promise
            .then(states => {
              if (states) {
                this.stateList = states;
                this.selectedState = this.stateList.find((state) => state.name == this.siteData.Region);
                  this.SystemResource.listcity({id: this.selectedState.id}).$promise
                  .then(cities => {
                    if (cities) {
                      this.cityList = cities;
                      this.selectedCity = this.cityList.find((city) => city.name == this.siteData.Locality);
                    }
                  });
              }
            });
          }

          this.loading = false;
          $("#editModal").modal("show");
        });
      }

      checkFields = () => {
        let valid = true;
        if(!this.siteData.Name) {
          toastr.error('Enter a site name.')
          valid = false;
        }
        if(!this.siteData.IP) {
          toastr.error('Enter an IP address.')
          valid = false;
        }
        if(!this.siteData.IoTHub) {
          toastr.error('Enter an IoT Hub.')
          valid = false;
        }
        if(this.siteData.id && !this.siteData.IoTDeviceId) {
          toastr.error('Enter an IoT Device Id.')
          valid = false;
        }
        if(this.siteData.id && !this.siteData.IoTPrimaryKey) {
          toastr.error('Enter an IoT Primary Key.')
          valid = false;
        }
        if(!this.siteData.Type) {
          toastr.error('Enter a site type.')
          valid = false;
        }
        if(!this.siteData.Address1) {
          toastr.error('Enter a site address.')
          valid = false;
        }
        if(!this.siteData.TimeZone) {
          toastr.error('Choose a timezone.')
          valid = false;
        }
        if(!this.siteData.Postcode) {
          toastr.error('Enter a site zip/postal.')
          valid = false;
        }
        if(!this.siteData.ContactName) {
          toastr.error('Enter a contact name for the site.')
          valid = false;
        }
        if(!this.siteData.ContactEmail) {
          toastr.error('Enter a contact email for the site.')
          valid = false;
        }
        if(!this.siteData.ContactPhone) {
          toastr.error('Enter a contact phone number for the site.')
          valid = false;
        }
        if(!this.siteData.OwnerId) {
          toastr.error('Select an owner for the site.')
          valid = false;
        }
        if(!this.siteData.CustomerId) {
          toastr.error('Select a customer for the site.')
          valid = false;
        }
        return valid;
      }

      updateSite = () => {
        if(this.checkFields()) {
          this.siteData.LiveDate = this.siteData['dates']['LiveDate'] ? this.siteData['dates']['LiveDate'].toISOString() : null;
          this.siteData.NotMonitoredDate = this.siteData['dates']['NotMonitoredDate'] ? this.siteData['dates']['NotMonitoredDate'].toISOString() : null;
          this.siteData.ClosedDate = this.siteData['dates']['ClosedDate'] ? this.siteData['dates']['ClosedDate'].toISOString() : null;
          this.siteData.ControlsDisabledDate = this.siteData['dates']['ControlsDisabledDate'] ? this.siteData['dates']['ControlsDisabledDate'].toISOString() : null;
          if (this.siteData.id) {
            this.saveSite();
          } else {
            this.createSite();
          }
        } else {
          toastr.error('Please fill out all required fields (in red)');
        }
      }

      saveSite = () => {
        let siteUpdate = JSON.parse(JSON.stringify(this.siteData));
        siteUpdate.Location = undefined;
        this.SiteResource.update({id: this.siteData.id}, siteUpdate).$promise.then(result => {
          if ( result.success ) {
            toastr.success(this.siteData.Name + ' updated');
            this.siteData = null;
            this.activeEdit = false;
            $("#editModal").modal("hide");
            $("#editEmailModal").modal("hide");
            this.getData();
          } else {
            toastr.error('Could not update site ' + this.siteData.Name);
          }
        });
      }

      createSite = () => {
        this.SiteResource.create(this.siteData).$promise.then(result => {
          if ( result.success ) {
            toastr.success(this.siteData.Name + ' created');
            this.siteData = null;
            this.activeEdit = false;
            $("#editModal").modal("hide");
            this.getData();
          } else {
            toastr.error('Could not create site ' + this.siteData.Name);
          }
        });
      }

      setOwner = () => {
        this.siteData['OwnerId'] = this.selectedOwner.id;
      }

      setCustomer = () => {
        this.siteData['CustomerId'] = this.selectedCustomer.id;
      }

      setEndCustomer = () => {
        this.siteData['EndCustomerId'] = this.selectedEndCustomer.id;
      }

      setUtility = () => {
        this.siteData['UtilityCompanyId'] = this.selectedUtility.id;
      }

      setSales = () => {
        this.siteData['SalesPersonId'] = this.selectedSales.id;
      }

      setManager = () => {
        this.siteData['ReportManagerId'] = this.selectedManager.id;
      }

      setSupplier = () => {
        this.selectedFill.Supplier = this.selectedSupplier;
        this.selectedFill.SupplierId = this.selectedSupplier.id;
      }

      getStates = () => {
        if (!this.loading) {
          this.siteData.Country = this.selectedCountry.name;
          this.selectedCity = null;
          this.selectedState = null;
          this.SystemResource.liststate({id: this.selectedCountry.id}).$promise
          .then(states => {
            if (states) {
              this.stateList = states;
            }
          });
        }
      }

      getCities = () => {
        if (!this.loading) {
          this.siteData.Region = this.selectedState.name;
          this.selectedCity = null;
          this.SystemResource.listcity({id: this.selectedState.id}).$promise
          .then(cities => {
            if (cities) {
              this.cityList = cities;
            }
          });
        }
      }

      setCity = () => {
        this.siteData.Locality = this.selectedCity.name;
      }

      getData = () => { 
        if (!this.activeEdit) {
          this.busy = true;

          this.SiteResource.listlive().$promise.then(results => {
            if (results) {
              for (let site of results) {
                if(site.hasOwnProperty('iodata')) {
                  let getDonut = this.getSiteDonut(site);
                  site['donut'] = getDonut.donut;
                  site['siteStatus'] =getDonut.status;
                }
              }
              this.allSites = results;
              this.getVisible();
            }
            this.busy = false; 
          });
        }
      }

      getVisible = () => {
        this.gridOptions['site'].data = [];
        for (let site of this.allSites) {
          if(this.showSite(site)) {
            this.gridOptions['site'].data.push(site);
          }
        }
      }

      checkDirty = () => {
        if(!this.isDirtyOneLine){
          this.isDirtyOneLine = true;
        }
      };

      getCellDataValue = (val:string, def?:string) => {
        let linecolor = def ? def : 'black';
        if(val) {
          try {
            let varObj = JSON.parse(val);
            linecolor = varObj['on'] || def || 'red';
          } catch (e) {
            linecolor = val;
          }
        }
        return linecolor;
      }

      getNode = (mainIndex:number, subIndex:number) => {
        if(subIndex == this.selectedCellX && mainIndex == this.selectedCellY) {
          this.clearSelected();
        } else {
          this.selectedCellX = subIndex;
          this.selectedCellY = mainIndex;
          this.selectedCell = this.oneLineGrid[mainIndex]['cells'][subIndex];
          this.selectedCell['lines'].forEach((line) => {
            if(line['color']) {
              try {
                line['$$color'] = JSON.parse(line['color']);
              } catch {
                line['$$color'] = undefined;
              }
            }
          });
          if(this.selectedCell['bg']) {
            if(this.selectedCell['bg']['bcolor']) {
              try {
                this.selectedCell['bg']['$$bcolor'] = JSON.parse(this.selectedCell['bg']['bcolor']);
              } catch {
                this.selectedCell['bg']['$$bcolor'] = undefined;
              }
            }
            if(this.selectedCell['bg']['fill']) {
              try {
                this.selectedCell['bg']['$$fill'] = JSON.parse(this.selectedCell['bg']['fill']);
              } catch {
                this.selectedCell['bg']['$$fill'] = undefined;
              }
            }
          }
          if(this.selectedCell['textcolor']) {
            try {
              this.selectedCell['$$textcolor'] = JSON.parse(this.selectedCell['textcolor']);
            } catch {
              this.selectedCell['$$textcolor'] = undefined;
            }
          }
        }
      }

      updateLineColorJSON = (line: any) => {
        try {
          line['$$color'] = JSON.parse(line['color']);
        } catch {
          line['$$color'] = undefined;
        }
      }

      updateBorderColorJSON = (background: any) => {
        try {
          background['$$bcolor'] = JSON.parse(background['bcolor']);
        } catch {
          background['$$bcolor'] = undefined;
        }
      }

      updateBgColorJSON = (background: any) => {
        try {
          background['$$fill'] = JSON.parse(background['fill']);
        } catch {
          background['$$fill'] = undefined;
        }
      }

      updateTextColorJSON = (text: any) => {
        try {
          text['$$textcolor'] = JSON.parse(text['textcolor']);
        } catch {
          text['$$textcolor'] = undefined;
        }
      }

      clearSelected = () => {
        this.selectedCellX = null;
        this.selectedCellY = null;
        this.selectedCell = null;
      }
      
      addRow = () => {
        if(!this.oneLineGrid) {
          this.oneLineGrid = [];
        }
        if(this.selectedCellY) {
          this.oneLineGrid.splice(this.selectedCellY, 0,
            {
              "cells": [
                {"unit":null,"name":null,"type":"","lines":[],"data":false},
                {"unit":null,"name":null,"type":"","lines":[],"data":false},
                {"unit":null,"name":null,"type":"","lines":[],"data":false},
                {"unit":null,"name":null,"type":"","lines":[],"data":false},
                {"unit":null,"name":null,"type":"","lines":[],"data":false},
                {"unit":null,"name":null,"type":"","lines":[],"data":false},
                {"unit":null,"name":null,"type":"","lines":[],"data":false},
                {"unit":null,"name":null,"type":"","lines":[],"data":false},
                {"unit":null,"name":null,"type":"","lines":[],"data":false},
                {"unit":null,"name":null,"type":"","lines":[],"data":false},
                {"unit":null,"name":null,"type":"","lines":[],"data":false},
                {"unit":null,"name":null,"type":"","lines":[],"data":false}
              ]
            }
          );
        } else {
          this.oneLineGrid.push(
            {
              "cells": [
                {"unit":null,"name":null,"type":"","lines":[],"data":false},
                {"unit":null,"name":null,"type":"","lines":[],"data":false},
                {"unit":null,"name":null,"type":"","lines":[],"data":false},
                {"unit":null,"name":null,"type":"","lines":[],"data":false},
                {"unit":null,"name":null,"type":"","lines":[],"data":false},
                {"unit":null,"name":null,"type":"","lines":[],"data":false},
                {"unit":null,"name":null,"type":"","lines":[],"data":false},
                {"unit":null,"name":null,"type":"","lines":[],"data":false},
                {"unit":null,"name":null,"type":"","lines":[],"data":false},
                {"unit":null,"name":null,"type":"","lines":[],"data":false},
                {"unit":null,"name":null,"type":"","lines":[],"data":false},
                {"unit":null,"name":null,"type":"","lines":[],"data":false}
              ]
            }
          ); 
        }
      };
  
      editColorAnimation = (type:string, obj:any) => {
        this.selectedAnimationType = type;
        this.selectedColorAnimation = obj;
        try {
          switch (this.selectedAnimationType) {
            case 'line':
              this.animationJSON = JSON.parse(obj['color']);
              break;
            case 'text':
              this.animationJSON = JSON.parse(obj['textcolor']);
              break;
            case 'fill':
              if(!obj['bg']) {
                obj['bg'] = { 'fill' : null };
              } else {
                if(!obj['bg']['fill']) {
                  obj['bg']['fill'] = null;
                }
              }
              this.animationJSON = JSON.parse(obj['bg']['fill']);
              break;
            case 'border':
              if(!obj['bg']) {
                obj['bg'] = {'bcolor':null};
              }
              this.animationJSON = obj['bg']['bcolor'] ? obj['bg']['bcolor'] : null;
              break;
          }
        } catch {
          this.animationJSON = {"on":"red","off":"lime","cond":[]};
        }
        if (!this.animationJSON) {
          this.animationJSON = {"on":"red","off":"lime","cond":[]};
        }
        $("#editAnimationModal").modal("show");
      }

      saveColorAnimation = () => {
        switch (this.selectedAnimationType) {
          case 'line':
            this.selectedColorAnimation['color'] = JSON.stringify(this.animationJSON);
            this.selectedColorAnimation['$$color'] = JSON.parse(JSON.stringify(this.animationJSON));
            break;
          case 'text':
            this.selectedColorAnimation['textcolor'] = JSON.stringify(this.animationJSON);
            this.selectedColorAnimation['$$textcolor'] = JSON.parse(JSON.stringify(this.animationJSON));
            break;
          case 'fill':
            this.selectedColorAnimation['bg']['fill'] = JSON.stringify(this.animationJSON);
            this.selectedColorAnimation['bg']['$$fill'] = JSON.parse(JSON.stringify(this.animationJSON));
            break;
          case 'border':
            this.selectedColorAnimation['bg']['bcolor'] = JSON.stringify(this.animationJSON);
            this.selectedColorAnimation['bg']['$$bcolor'] = JSON.parse(JSON.stringify(this.animationJSON));
            break;
        }
        this.selectedAnimationType = null;
        this.selectedColorAnimation = null;
        this.animationJSON = null;
        $("#editAnimationModal").modal("hide");
        this.checkDirty();
      }
  
      selectedCellBorder = (cell, row, col) => {
        let border = 'border border-gray-800';
        if (this.selectedCellX == row && this.selectedCellY == col) {
          border = 'border-2 border-red-500';
        }
        return border;
      };

      clearCell = () => {
        this.selectedCell['unit'] = null;
        this.selectedCell['name'] = null;
        this.selectedCell['type'] = "";
        this.selectedCell['lines'] = [];
        this.selectedCell['bg'] = undefined;
        this.selectedCell['text'] = undefined;
      };
  
      removeRow = () => {
        this.oneLineGrid.splice(this.selectedCellY, 1);
        this.clearSelected();
      };
      
      copyCell = () => {
        this.selectedCellBackup = angular.toJson(this.selectedCell);
        this.selectedCellBackupJSON = [JSON.parse(this.selectedCellBackup)];
      }
  
      pasteCell = () => {
        this.oneLineGrid[this.selectedCellY]['cells'][this.selectedCellX] = JSON.parse(this.selectedCellBackup);
        this.selectedCell = this.oneLineGrid[this.selectedCellY]['cells'][this.selectedCellY];
        this.checkDirty();
      }
  
      utilBadge = (status) => {
        let result = 'bg-gs-light';
        if (status == undefined || status == null) {
          result = 'bg-gray-400';
        }
        if(status) {
          if(status.indexOf('OFF') > -1) {
            result = 'bg-red-500';
          }
        }
        return result;
      }

      genBadge = (status) => {
        let result = 'bg-gs-light';
        if (status == undefined || status == null) {
          result = 'bg-gray-400';
        }
        if(status) {
          if(status.indexOf('ON') > -1) {
            result = 'bg-green-500';
          }
        }
        return result;
      }
      
      addOr = () => {
        this.animationJSON.cond.push([{"type":null, "unit":null, "var":null, "val":null, "comp":null}]);
      }

      addAnd = (cOr: any) => {
        cOr.push({"type":null, "unit":null, "var":null, "val":null, "comp":null});
      }

      updateOneLine = () => {
        this.SiteResource.updateOneLine({ SiteId: this.siteData.id, OneLine: angular.toJson(this.oneLineGrid)}).$promise.then((result) => {
          if(result.success === true) {
            toastr.success("Saved Oneline");
            this.activeEdit = false;
            $("#editOneLineModal").modal("hide");
            this.isDirtyOneLine = false;
          } else {
            toastr.error(result.message);
          }
        });
      }

      getEmailList = (notificationString) => {
        let emailList = "";
        if (notificationString) {
          let emailListJSON = JSON.parse(notificationString);
          emailList = emailListJSON.join(', ');
        }
        return emailList;
      }

      editEmailList = (site) => {
        this.siteData = JSON.parse(JSON.stringify(site));
        this.emailListToEdit = [];
        if (this.siteData.NotificationList) {
          this.emailListToEdit = JSON.parse(this.siteData.NotificationList);
        }
        $("#editEmailModal").modal("show");
      }

      addNewEmailToList = (email) => {
        //check email address
        if (this.validateEmail(email)) {
          if (this.emailListToEdit.indexOf(email) == -1) {
            this.emailListToEdit.push(email);
          }
        } else {
          toastr.error('Not a valid email address');
        }
      }

      saveNotificationList = () => {
        this.siteData.NotificationList = JSON.stringify(this.emailListToEdit);
        this.saveSite();
      }

      showSite = (site) => {
        if ((this.notableCommaFilter(this.searchText.toLowerCase(), site.Name.toLowerCase())
          && ((this.showDisconnected) || (!this.showDisconnected && site.iodata.connected))
          && ((this.showMobiles) || (!this.showMobiles && site.Type != 'MOBILE'))
          && ((this.showMicroGrid) || (!this.showMicroGrid && site.Type != 'MICRO_GRID'))
          && ((this.showGenOnly) || (!this.showGenOnly && site.Type != 'GENERATOR_ONLY'))
          && ((this.showDataCenter) || (!this.showDataCenter && site.Type.type != 'DATA_CENTER'))
          && ((this.showBESS) || (!this.showBESS && site.Type.type != 'BESS'))
          && ((this.showOther) || (!this.showOther && site.Type.type != 'UNKNOWN'))
          && ((this.showInAlarm) || (!this.showInAlarm && (!site.inAlarm && !site.inCritAlarm)))
          && ((this.showNoAlarm) || (!this.showNoAlarm && (site.inAlarm || site.inCritAlarm)))
          && ((this.showStopped) || (!this.showStopped && this.isTrue(site.running)))
          && ((this.showRunning) || (!this.showRunning && this.isFalse(site.running)))
        ) && (
          (!this.showLowFuel && !this.showCritLowFuel && !this.showLowDEF && !this.showCritLowDEF)
          || (this.showLowFuel && this.isTrue(site.lowFuelAlarm))
          || (this.showCritLowFuel && this.isTrue(site.critLowFuelAlarm))
          || (this.showLowDEF && this.isTrue(site.lowDEFAlarm))
          || (this.showCritLowDEF && this.isTrue(site.critLowDEFAlarm))
        )) {
          return true;
        }
        return false;
      }

      applyFilters = (save = true) => {
        this.applyGlobalFilters(save);
        if (save) {
          $('#siteFilterDropdown').dropdown('toggle');
        }
        this.getVisible();
      }
  
      cancelFilters = () => {
        this.cancelGlobalFilters();
        $('#siteFilterDropdown').dropdown('toggle');
      }

      getSiteDonut = (site) => {
        let connected = site.iodata ? site.iodata.connected : 0;
        let running = site.iodata ? site.iodata.tags.running : 0;
        let inAlarm = site.inAlarm ? site.inAlarm : 0;
        let inCritAlarm = site.inCritAlarm ? site.inCritAlarm : 0;
        let runningBg = 'bg-running';
        if (running && inAlarm && !inCritAlarm) {
          runningBg = 'bg-running-in-alarm';
        } else if (running && inCritAlarm ) {
          runningBg = 'bg-running-in-crit-alarm'
        }
        let iconHTML = '';
        let statusHTML = '';
        if (site.hasOwnProperty("iodata")) {
          let siteSummaryData = this.getSiteSummaryData(site);
          let donut = this.getDonut(siteSummaryData);
          let load = 'n/a';
          if(siteSummaryData) {
            if(siteSummaryData.hasOwnProperty('L')) {
              load = (Math.round(siteSummaryData['L']['kw'] / 10) / 100).toFixed(2);
            }
          }
          statusHTML += `
          <div class="flex items-center gap-[8px] text-[10px] leading-[1.4] mt-[2px] text-text-disabled font-semibold shrink-0 ${connected == 1 ? 'visible' : 'collapse'} ">
            <span class="flex items-center gap-[2px]">
              <span class="w-[7px] aspect-square rounded-full bg-gs-success"></span>
              <span class="text-text-med-em">${ this.capPercent(donut.nonrenewableasset + donut.renewableasset) }% Assets</span>
            </span>
            <span class="flex items-center gap-[2px]">
              <span class="w-[7px] aspect-square rounded-full bg-gs-warning"></span>
              <span class="text-text-med-em">${ this.capPercent(donut.util) }% Utility</span>
            </span>
          </div>
          <div class="flex items-center gap-[8px] text-[10px] leading-[1.4] mt-[2px] text-text-disabled font-semibold shrink-0 ${connected == 0 ? 'visible' : 'collapse'}">
            <span class="text-text-med-em">Disconnected</span>
          </div>`;

          // If Not Running
          if (!running) {
            iconHTML = `
              <div class="w-16 h-16 flex justify-center items-center rounded-full relative shrink-0 bg-not-running-disconnected">
              <div class="w-full flex justify-center items-center aspect-square rounded-full overflow-hidden relative">
                <div class="absolute inset-0 rotate-[180deg]" style="mask: radial-gradient(farthest-side, rgba(0, 0, 0, 0) calc(99% - 6px), rgb(0, 0, 0) calc(100% - 6px)); 
                  background-image: ${ donut.style }">
                </div>
                <div class="flex flex-col justify-center items-center">
                  <span class="tracking-tighter leading-none font-medium text-sm text-white">${load}</span>
                  <span class="tracking-tighter leading-none text-[10px] text-white">MW</span>
                </div>`;
          }
          // If Running
          if (running) {
            iconHTML = `
              <div class="w-16 h-16 flex justify-center items-center rounded-full relative shrink-0 ${ runningBg }">
                <div class="w-full flex justify-center items-center aspect-square rounded-full overflow-hidden relative">
                  <div class="absolute inset-0 rotate-[180deg]" style="mask: radial-gradient(farthest-side, rgba(0, 0, 0, 0) calc(99% - 6px), rgb(0, 0, 0) calc(100% - 6px)); 
                    background-image: ${ donut.style }">
                  </div>
                  <div class="flex flex-col justify-center items-center">
                    <span class="tracking-tighter leading-none font-medium text-sm text-white">${load}</span>
                    <span class="tracking-tighter leading-none text-[10px] text-white">MW</span>
                  </div>`;
          }

          // Disconnected
          if (!connected) {
            iconHTML = `
              <div class="w-16 h-16 flex justify-center items-center rounded-full relative shrink-0 bg-not-running-disconnected">
              <div class="w-full flex justify-center items-center aspect-square rounded-full overflow-hidden relative">
                <img class="disconnected-icon">`;
          }
          iconHTML += `
            </div>
            ${(inAlarm && !inCritAlarm) ? `<div class="w-7 h-7 absolute -bottom-1 -right-1 flex justify-center items-center aspect-squar rounded-full bg-gs-warning"><img class="h-5 w-5 alarm-icon-white"></div>` : ''}
            ${inCritAlarm ? `<div class="w-7 h-7 absolute -bottom-1 -right-1 flex justify-center items-center aspect-squar rounded-full bg-gs-danger"><img class="h-5 w-5 alarm-icon-white"></div>` : ''}
          </div>
          `;
        }
        return { donut: this.$sce.trustAsHtml(iconHTML), status: this.$sce.trustAsHtml(statusHTML) };
      }
    }
    angular.module('gsapp').controller('SitesController', SitesController);
  }