namespace GSapp.filters
{
  'use strict';

  export class statusValue
  {
    static filter() {

      return (input : any) => {
        if(_.isUndefined(input) || _.isNull(input)) {
          return 'n/a';
        }
        let rtn = input;
        try {
          rtn = "";
          let statObj = JSON.parse(input);
          Object.keys(statObj).forEach((key) => {
            if(rtn.length > 0) {
              rtn += ', ';
            }
            rtn += key + ":" + statObj[key];
          });
        } catch {
          rtn = input.replace(/"/g, "");
        }
        return rtn;
      };
    }
  }

  angular.module('gsapp').filter('statusValue',  statusValue.filter);
}
