namespace GSapp.controllers {
  'use strict';

  export class SupplierController extends controllers.BaseController {
    busy: boolean = false;
    loading: boolean = false;
    selectedSupplier: any = null;
    selectedCountry: any = null;
    countryList: any = [];
    selectedState: any = null;
    stateList: any = [];
    selectedCity: any = null;
    cityList: any = [];
    searchText: string = null;
    gridOptions: any = {};
    gridApi: any = {};

    static readonly $inject = [
      'SupplierResource',
      'uiGridConstants',
      'SystemResource',
      '$window', 
      '$q', '$cookies', 'ReportResource'
    ];

    constructor(
      public SupplierResource: interfaces.ISupplierResource,
      public uiGridConstants: any,
      public SystemResource: interfaces.ISystemResource,
      public $window: ng.IWindowService,
      public $q: ng.IQService,
      public $cookies: any,
      public ReportResource: interfaces.IReportResource ) {
        super(SystemResource, $window, $q, $cookies, ReportResource);
        
        let self = this;

        self.gridOptions['supplier'] = {
          onRegisterApi: function(gridApi){
            self.gridApi['supplier'] = gridApi;
          },
          enableFiltering: true,
          enableRowHashing:false,
          enableHorizontalScrollbar: this.uiGridConstants.scrollbars.WHEN_NEEDED,
          rowHeight: this.grid2RowHeight,
          data: [],
          columnDefs :[
            { name: 'Supplier', field: 'Name', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."} },
            { name: 'Address', field: 'Address1', filter: { condition: this.addressFilter, placeholder: "Comma separated..."},
              cellTemplate: `<div class="ui-grid-cell-contents">
                {{ row.entity.Address1 }} {{ row.entity.Locality }},<br>{{ row.entity.Region }} {{ row.entity.Postcode }} {{ row.entity.Country }}
                </div>`
            },
            { name: 'TimeZone', field: 'TimeZone', width: '198', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."} },
            { name: 'Contact', field: 'ContactName', filter: { condition: this.nameEmailPhoneFilter, placeholder: "Comma separated..."},
              cellTemplate: `<div class="ui-grid-cell-contents flex flex-col overflow-y-auto text-sm">
                <div class="flex flex-row">
                  <div class="w-1/4 ui-grid-cell-table-th">
                    Name
                  </div>
                  <div class="w-3/4 ui-grid-cell-table-td">
                    {{ row.entity.ContactName }}
                  </div>
                </div>
                <div class="flex flex-row">
                  <div class="w-1/4 ui-grid-cell-table-th">
                    Email
                  </div>
                  <div class="w-3/4 ui-grid-cell-table-td">
                    {{ row.entity.ContactEmail }}
                  </div>
                </div>
                <div class="flex flex-row">
                  <div class="w-1/4 ui-grid-cell-table-th">
                    Phone
                  </div>
                  <div class="w-3/4 ui-grid-cell-table-td">
                    {{ row.entity.ContactPhone }}
                  </div>
                </div>
              </div>`
            },
            { name: 'Diesel', field: 'Diesel',width: '86',
            cellTemplate: `<div class="ui-grid-cell-contents">
              <img class="circle-check-icon origin-center pr-1" ng-show="COL_FIELD"></img>
              </div>`,
            filter: {
              term: null,
              type: uiGridConstants.filter.SELECT,
              selectOptions: [ { value: true, label: 'Diesel' }, { value: false, label: 'No Diesel'} ]
            }
            },
            { name: 'DEF', field: 'DEF', width: '86',
              cellTemplate: `<div class="ui-grid-cell-contents">
              <img class="circle-check-icon origin-center pr-1" ng-show="COL_FIELD"></img></div>`,
                filter: {
                  term: null,
                  type: uiGridConstants.filter.SELECT,
                  selectOptions: [ { value: true, label: 'DEF' }, { value: false, label: 'No DEF'} ]
                }
            },
            { name: 'Propane', field: 'Propane', width: '86',
              cellTemplate: `<div class="ui-grid-cell-contents">
                  <img class="circle-check-icon origin-center pr-1" ng-show="COL_FIELD"></img>
                  </div>`,
                filter: {
                  term: null,
                  type: uiGridConstants.filter.SELECT,
                  selectOptions: [ { value: true, label: 'Propane' }, { value: false, label: 'No Propane'} ]
                }
            },
            { name: 'Notes', field: 'Notes', filter: { condition: this.nameEmailPhoneFilter, placeholder: "Comma separated..."} },
            { name: ' ', width: '32', enableFiltering: false, enableSorting: false,
              cellTemplate: `<div class="ui-grid-cell-contents m-0">
                <button class="w-full w-full m-0", type="button", id="supplierDropdownMenuButton", data-bs-toggle="dropdown", aria-expanded="false">
                  <img class="hamburger-vertical-icon"><img>
                </button>
                <ul class="dropdown-menu dropdown-menu-end", aria-labelledby="supplierDropdownMenuButton">
                  <li>
                    <button class="dropdown-item" ng-click="grid.appScope.ctrl.openEditModal(row.entity)">Edit</button>
                  </li>
                </ul></div>`
            }
          ]
        }
        this.getData();
      }

      openEditModal = (supplier) => {
        this.loading = true;
        this.selectedSupplier = JSON.parse(JSON.stringify(supplier));
        this.selectedCountry = this.countryList.find((country) => 
          country.name == this.selectedSupplier.Country);
        this.SystemResource.liststate({id: this.selectedCountry.id}).$promise
        .then(states => {
          if (states) {
            this.stateList = states;
            this.selectedState = this.stateList.find((state) => state.name == this.selectedSupplier.Region);
              this.SystemResource.listcity({id: this.selectedState.id}).$promise
              .then(cities => {
                if (cities) {
                  this.cityList = cities;
                  this.selectedCity = this.cityList.find((city) => city.name == this.selectedSupplier.Locality);
                }
              });
          }
        });
        this.loading = false;
        $("#editModal").modal("show");
      }

      openCreateModal = () => {
        this.selectedCountry = null;
        this.selectedState = null;
        this.selectedCity = null;
        this.selectedSupplier = {id: null, Name: null, Address1: null, Locality: null, Region: null, Postcode: null, Country: null, ContactName: null, ContactEmail: null, ContactPhone: null, TimeZone: null, Notes: null };
        $("#editModal").modal("show");
      }

      updateSupplier = () => {
        if (this.selectedSupplier.id) {
          this.saveSupplier();
        } else {
          this.createSupplier();
        }
      }

      saveSupplier = () => {
        this.SupplierResource.update({id: this.selectedSupplier.id}, this.selectedSupplier).$promise
        .then(result => {
          if ( result ) {
            toastr.success('Supplier ' + this.selectedSupplier.Name + ' updated');
            this.selectedSupplier = null;
            $("#editModal").modal("hide");
            this.getData();
          } else {
            toastr.error('Could not update supplier ' + this.selectedSupplier.Name);
          }
        });
      }

      createSupplier = () => {
        this.SupplierResource.create(this.selectedSupplier).$promise
        .then(result => {
          if ( result ) {
            toastr.success('Supplier ' + this.selectedSupplier.Name + ' created');
            this.selectedSupplier = null;
            $("#editModal").modal("hide");
            this.getData();
          } else {
            toastr.error('Could not create supplier ' + this.selectedSupplier.Name);
          }
        });
      }

      getStates = () => {
        if (!this.loading) {
          this.selectedSupplier.Country = this.selectedCountry.name;
          this.selectedCity = null;
          this.selectedState = null;
          this.SystemResource.liststate({id: this.selectedCountry.id}).$promise
          .then(states => {
            if (states) {
              this.stateList = states;
            }
          });
        }
      }

      getCities = () => {
        if (!this.loading) {
          this.selectedSupplier.Region = this.selectedState.name;
          this.selectedCity = null;
          this.SystemResource.listcity({id: this.selectedState.id}).$promise
          .then(cities => {
            if (cities) {
              this.cityList = cities;
            }
          });
        }
      }

      setCity = () => {
        this.selectedSupplier.Locality = this.selectedCity.name;
      }
      
      getData = () => {
        this.busy = true;

        this.SupplierResource.list().$promise
          .then(results => {
            if (results) {
              this.gridOptions['supplier'].data = results;
            }
            this.busy = false; 
          });
      }

    }
    angular.module('gsapp').controller('SupplierController', SupplierController);
  }