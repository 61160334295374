namespace GSapp.filters
{
  'use strict';

  export class formatLocation
  {
    /** 
     * @author Michael Guenther
     * @name formatValue
     * @type filter
     * @description Return a blank space or a formatted float with 'precision' decimal places.
     * @param input any value.  Numbers and special cases will be formatted and returned.
     * @param status a value that will be treated as 'comm fail' and not displayed as a number.
     * @returns a formatted float value or special cases (&nbsp;, MBErr, N/A, or original value).
     * @memberof formatLocation
    */
    static filter() {

      return (input : any, status : any) => {
        let badLocation = false;
        //If the value provided is not null, format the value.
        if(_.isUndefined(input) || _.isNull(input)) {
          return '\u00A0';
        }
        if(!_.isUndefined(status) && !_.isNull(status)) {
          if(status === "false") {
            //If there is a comm failure, return 'MBErr' instead of a number.
            badLocation = true;
          }
        }
        //Check to see if the value provided is a number or not.
        if(isNaN(input)) {
          //Don't format anything, return the original value.
          return input;
        }
        //Check to see if a precision was specified.
        return badLocation ? '[' + input.toFixed(8) + ']' : input.toFixed(8);
      };
    }
  }

  angular.module('gsapp').filter('formatLocation',  formatLocation.filter);
}
