namespace GSapp.controllers {
  'use strict';

  export class RoleController extends controllers.BaseController {
    busy: boolean = false;
    loading: boolean = false;
    selectedRole: any = null;
    siteList: any = [];
    customerList: any = [];
    utilityList: any = [];
    selectedSite: any = null;
    selectedCustomer: any = null;
    selectedUtility: any = null;
    newPermission: any = {};
    gridOptions: any = {};
    gridApi: any = {};

    static readonly $inject = [
      'RoleResource',
      'SiteResource',
      'CustomerResource',
      'UtilityResource',
      'uiGridConstants',
      'SystemResource',
      '$window', 
      '$q', '$cookies', 'ReportResource'
    ];

    constructor(
      public RoleResource: interfaces.IRoleResource,
      public SiteResource: interfaces.ISiteResource,
      public CustomerResource: interfaces.ICustomerResource,
      public UtilityResource: interfaces.IUtilityResource,
      public uiGridConstants: any,
      public SystemResource: interfaces.ISystemResource,   
      public $window: ng.IWindowService,
      public $q: ng.IQService,
      public $cookies: any,
      public ReportResource: interfaces.IReportResource ) {
        super(SystemResource, $window, $q, $cookies, ReportResource);
        
        let self = this;

        self.gridOptions['role'] = {
          onRegisterApi: function(gridApi){
            self.gridApi['role'] = gridApi;
          },
          enableFiltering: true,
          enableRowHashing:false,
          enableHorizontalScrollbar: this.uiGridConstants.scrollbars.WHEN_NEEDED,
          rowHeight: this.gridRowHeight,
          data: [],
          columnDefs :[
            { name: 'Role', field: 'Name', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."} },
            { name: 'Permissions', field: 'Permissions', filter: { condition: this.arrayNameFilter, placeholder: "Comma separated..."},
              cellTemplate: `<div class="ui-grid-cell-contents flex flex-wrap gap-1">
                <span class="ui-grid-cell-table-th flex-1" ng-repeat="permission in row.entity.Permissions">{{ permission.Name }}</span>
              </div>`
            },
            { name: ' ', width: '32', enableFiltering: false, enableSorting: false,
            cellTemplate: `<div class="ui-grid-cell-contents m-0">
              <button class="w-full w-full m-0", type="button", id="roleDropdownMenuButton", data-bs-toggle="dropdown", aria-expanded="false">
                <img class="hamburger-vertical-icon"><img>
              </button>
              <ul class="dropdown-menu dropdown-menu-end", aria-labelledby="roleDropdownMenuButton">
                <li>
                  <button class="dropdown-item" ng-click="grid.appScope.ctrl.openEditModal(row.entity)">Edit Role</button>
                </li>
                <li>
                  <button class="dropdown-item" ng-click="grid.appScope.ctrl.openPermissionEditModal(row.entity)">Edit Role Permissions</button>
                </li>
                <li>
                  <button class="dropdown-item" ng-click="grid.appScope.ctrl.deleteRole(row.entity)">Delete Role</button>
                </li>
              </ul></div>`
            }
          ]
        }
        this.getData();
      }

      openEditModal = (role) => {
        this.loading = true;
        this.selectedRole = JSON.parse(JSON.stringify(role));
        this.loading = false;
        $("#editModal").modal("show");
      }

      openPermissionEditModal = (role) => {
        this.loading = true;
        this.selectedRole = JSON.parse(JSON.stringify(role));
        this.openPermissionModal();
      }

      openCreateModal = () => {
        this.selectedRole = {id: null, Name: null};
        $("#editModal").modal("show");
      }

      openPermissionModal = () => {
        let selectPromises = [];
        selectPromises.push(this.SiteResource.list().$promise);
        selectPromises.push(this.CustomerResource.list().$promise);
        selectPromises.push(this.UtilityResource.list().$promise);
        this.$q.all(selectPromises).then((results: any[][]) => {
          if (results[0]) {
            this.siteList = results[0];
          }
          if (results[1]) {
            this.customerList = results[1];
          }
          if (results[2]) {
            this.utilityList = results[2];
          }
          this.loading = false;
          $("#editPermissionModal").modal("show");
        });
      }

      closePermissionModal = () => {
        this.getData();
        $("#editPermissionModal").modal("hide");
      }

      updateRole = () => {
        if (this.selectedRole.id) {
          this.saveRole();
        } else {
          this.createRole();
        }
      }

      saveRole = () => {
        this.RoleResource.update({id: this.selectedRole.id}, this.selectedRole).$promise
        .then(result => {
          if ( result ) {
            toastr.success('Role ' + this.selectedRole.Name + ' updated');
            this.selectedRole = null;
            $("#editModal").modal("hide");
            this.getData();
          } else {
            toastr.error('Could not update role ' + this.selectedRole.Name);
          }
        });
      }

      createRole = () => {
        this.RoleResource.create(this.selectedRole).$promise
        .then(result => {
          if ( result ) {
            toastr.success('Role ' + this.selectedRole.Name + ' created');
            this.selectedRole = null;
            $("#editModal").modal("hide");
            this.getData();
          } else {
            toastr.error('Could not create role ' + this.selectedRole.Name);
          }
        });
      }

      setSite = () => {
        this.selectedCustomer = null;
        this.selectedUtility = null;
        this.newPermission['CustomerId'] = null;
        this.newPermission['UtilityId'] = null;
        this.newPermission['SiteId'] = this.selectedSite.id;
      }

      setCustomer = () => {
        this.selectedSite = null;
        this.selectedUtility = null;
        this.newPermission['SiteId'] = null;
        this.newPermission['UtilityId'] = null;
        this.newPermission['CustomerId'] = this.selectedCustomer.id;
      }

      setUtility = () => {
        this.selectedSite = null;
        this.selectedCustomer = null;
        this.newPermission['SiteId'] = null;
        this.newPermission['CustomerId'] = null;
        this.newPermission['UtilityId'] = this.selectedUtility.id;
      }

      addPermission = () => {
        this.newPermission.RoleId = this.selectedRole.id;
        if(this.newPermission.Name) {
          this.RoleResource.addPermission(this.newPermission).$promise.then(result => {
            if(result) {
              toastr.success('Permission added to Role');
              this.RoleResource.get({id:this.selectedRole.id}).$promise.then(role => {
                this.selectedRole = role;
                this.newPermission = {};
                this.selectedCustomer = null;
                this.selectedSite = null;
                this.selectedUtility = null;
              });
            } else {
              toastr.error('Could not create permission');
            }
          });
        }
      }

      deleteRole= (role) => {
        this.RoleResource.deleteRole({id: role.id}).$promise.then(result => {
          if(result) {
            toastr.success('Role deleted');
            this.getData();
          } else {
            toastr.error('Could not delete permission');
          }
        });
      }

      deletePermission= (perm) => {
        this.RoleResource.deletePermission({id: perm.id}).$promise.then(result => {
          if(result) {
            toastr.success('Permission deleted from Role');
            this.RoleResource.get({id:this.selectedRole.id}).$promise.then(role => {
              this.selectedRole = role;
            });
          } else {
            toastr.error('Could not delete permission');
          }
        });
      }

      getData = () => {
        this.busy = true;

        this.RoleResource.list().$promise
          .then(results => {
            if (results) {
              this.gridOptions['role'].data = results;
            }
            this.busy = false; 
          });
      }

    }
    angular.module('gsapp').controller('RoleController', RoleController);
  }