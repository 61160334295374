namespace GSapp.controllers {
  'use strict';

  export class DashboardController extends controllers.BaseController {
    busy: boolean = false;
    pendingList: any = [];
    searchIdle: string = '';
    searchRunning: string = '';
    gridOptions: any = {};
    gridApi: any = {};

    static readonly $inject = [
      'SiteResource',
      'ScheduleResource',
      'uiGridConstants',
      'SystemResource',
      '$window',
      '$q', '$cookies', 'ReportResource'
    ];

    constructor(
      public SiteResource: interfaces.ISiteResource,
      public ScheduleResource: interfaces.IScheduleResource,
      public uiGridConstants: any,
      public SystemResource: interfaces.ISystemResource,
      public $window: ng.IWindowService,
      public $q: ng.IQService,
      public $cookies: any,
      public ReportResource: interfaces.IReportResource
    ) {
      super(SystemResource, $window, $q, $cookies, ReportResource);

      let self = this;

      self.gridOptions['idle'] = {
        onRegisterApi: function (gridApi) {
          self.gridApi['idle'] = gridApi;
        },
        enableFiltering: true,
        enableHorizontalScrollbar: this.uiGridConstants.scrollbars.WHEN_NEEDED,
        rowHeight: this.gridRowHeight,
        data: [],
        columnDefs: [
          {
            name: '', field: 'iodata.connected', width: '38', enableColumnMenu: false,
            cellTemplate: '<div class="ui-grid-cell-contents flex justify-center"><img class="self-center disconnected-icon filter-white origin-center" ng-show="!row.entity.iodata || row.entity.iodata.connected == 0" title="Connection Down"</img></div>',
            filter: {
              term: null,
              type: uiGridConstants.filter.SELECT,
              selectOptions: [{ value: null, label: 'All' }, { value: '0', label: 'Disconnected' }, { value: '1', label: 'Connected' }]
            },
          },
          {
            name: 'alarms', field: 'inAlarm', displayName: '', enableColumnMenu: false, width: '38', 
            cellTemplate: `<div class="ui-grid-cell-contents flex justify-center">
              <img class="self-center filter-white alarm-icon origin-center" ng-show="row.entity.inAlarm && !row.entity.inCritAlarm" title="Active Alarms"></img>
              <img class="self-center filter-white volume-up-icon origin-center" ng-show="row.entity.inCritAlarm" title="Active Critical Alarms"></img>
            </div>`,
            filter: {
              term: null,
              type: uiGridConstants.filter.SELECT,
              selectOptions: [{ value: false, label: 'No Alarms' }, { value: true, label: 'Alarms' }]
            }
          },
          {
            name: 'Site', field: 'Name', width: '268', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..." },
            cellTemplate: `<div class="ui-grid-cell-contents flex">
              <div class="self-center flex flex-row gap-1">
                <a ng-hide="row.entity.ExternalViewLink != null" ng-href="/site/{{row.entity.id}}", target="_blank" title={{row.entity.Notes}}><img class="arrow-fwd pl-2"></img></a>
                <a ng-show="row.entity.ExternalViewLink != null" ng-href="{{row.entity.ExternalViewLink}}" title='Go To Local Site'><img class="arrow-fwd pl-2"></img></a>
              <span class="font-bold">{{ COL_FIELD }}</span>
              </div>
            </div>`
          },
          {
            name: 'Last Update', field: 'iodata.timestamp', maxWidth: '208',
            cellTemplate: `<div class="ui-grid-cell-contents flex">
              <span class='self-center'>{{ COL_FIELD | date:grid.appScope.ctrl.dateFilterFormat:grid.appScope.ctrl.timeZone }}</span>
            </div>`
          },
          {
            name: 'Weather', field: 'WeatherJSON', width: '268', enableFiltering: false,
            cellTemplate: `<div class="ui-grid-cell-contents flex justify-between" ng-if="COL_FIELD">
              <div class="self-center flex flex-row gap-2 w-full">
                <i class="fa-solid fa-temperature-half"></i>
                <span class="origin-center">{{grid.appScope.ctrl.tempConvert(COL_FIELD.main.temp)}}{{grid.appScope.ctrl.units === 'imperial' ? '&#8457;' : '&#8451'}}</span>
              </div>
              <div class="self-center flex flex-row gap-2 w-full">
                <i class="fa-solid fa-wind"></i>
                <i class="fa-solid fa-up-long fa-rotate-by" style="--fa-rotate-angle: {{COL_FIELD.wind.deg}}deg;"></i>
                <span class="origin-center">{{grid.appScope.ctrl.speedConvert(COL_FIELD.wind.speed)}}{{grid.appScope.ctrl.units === 'imperial' ? 'mph' : 'km/h'}}</span>
              </div>
              <div class="self-center flex flex-row gap-2 w-full">
                <img class="w{{COL_FIELD.weather[0].icon}} h-6"></img>
                <span class="origin-center">{{COL_FIELD.weather[0].main}}</span>
              </div>
            </div>`
          },
          {
            name: 'Site Status', field: 'iodata.tags', minWidth: '178', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..." },
            cellTemplate: `<div class="ui-grid-cell-contents flex justify-left gap-2">
              <span class='self-center text-wrap'>{{ COL_FIELD.status | statusValue }}</span>
              <span class='self-center text-wrap'>{{ COL_FIELD.opStatus | statusValue }}</span>
            </div>`
          },
          {
            name: 'Utility Status', field: 'iodata.tags.U.status', width: '178', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..." },
              cellTemplate: `<div class="ui-grid-cell-contents">
              <div class="w-full flex flex-wrap justify-center h-full overflow-y-auto" 
                ng-class="{ 
                  'bg-gs-unknown': (COL_FIELD == undefined || COL_FIELD == null),
                  'bg-gs-warn-fade': (COL_FIELD.indexOf('OFF') > -1), 
                  'bg-gs-success-fade': ((COL_FIELD.indexOf('MANUAL') > -1) || (COL_FIELD.indexOf('AUTO') > -1)), 
                  'bg-gs-danger-fade': (COL_FIELD.indexOf('FAULT') > -1),
                }">
                <div class='self-center text-wrap'>{{ COL_FIELD | statusValue }}</div>
              </div>
            </div>`
          },
          {
            name: 'Generator Status', field: 'iodata.tags.G.status', width: '178', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..." },
            cellTemplate: `<div class="ui-grid-cell-contents">
              <div class="w-full flex flex-wrap justify-center h-full overflow-y-auto" 
                ng-class="{ 
                  'bg-gs-unknown': (COL_FIELD == undefined || COL_FIELD == null),
                  'bg-gs-warn-fade': (COL_FIELD.indexOf('OFF') > -1), 
                  'bg-gs-success-fade': ((COL_FIELD.indexOf('MANUAL') > -1) || (COL_FIELD.indexOf('AUTO') > -1)), 
                  'bg-gs-danger-fade': (COL_FIELD.indexOf('FAULT') > -1),
                }">
                <div class='self-center text-wrap'>{{ COL_FIELD | statusValue }}</div>
              </div>
            </div>`
          },
          {
            name: ' ', width: '32', enableColumnMenu: false, enableFiltering: false, enableSorting: false,
            cellTemplate: `<div class="ui-grid-cell-contents m-0">
              <button class="w-full h-full m-0", type="button", id="roleDropdownMenuButton", data-bs-toggle="dropdown", aria-expanded="false">
                <img class="hamburger-vertical-icon"><img>
              </button>
              <ul class="dropdown-menu dropdown-menu-end", aria-labelledby="siteDropdownMenuButton">
                <li>
                  <a class="dropdown-item", ng-href="/site/{{row.entity.id}}", target="_blank">Open Site Detail</a>
                </li>
              </ul></div>`
            }

        ]
      }
      self.gridOptions['running'] = {
        onRegisterApi: function (gridApi) {
          self.gridApi['running'] = gridApi;
        },
        enableFiltering: true,
        rowHeight: this.gridRowHeight,
        enableHorizontalScrollbar: this.uiGridConstants.scrollbars.WHEN_NEEDED,
        data: [],
        columnDefs: [
          {
            name: '', field: 'iodata.connected', width: '38', enableColumnMenu: false,
            cellTemplate: '<div class="ui-grid-cell-contents flex justify-center"><img class="self-center disconnected-icon filter-white origin-center" ng-show="!row.entity.iodata || row.entity.iodata.connected == 0" title="Connection Down"</img></div>',
            filter: {
              term: null,
              type: uiGridConstants.filter.SELECT,
              selectOptions: [{ value: null, label: 'All' }, { value: '0', label: 'Disconnected' }, { value: '1', label: 'Connected' }]
            },
          },
          {
            name: 'alarms', field: 'inAlarm', displayName: '', enableColumnMenu: false, width: '38',
            cellTemplate: `<div class="ui-grid-cell-contents flex justify-center gap-2">
                  <img class="self-center alarm-icon filter-white origin-center" ng-show="row.entity.inAlarm && !row.entity.inCritAlarm" title="Active Alarms"></img>
                  <img class="self-center volume-up-icon filter-white origin-center" ng-show="row.entity.inCritAlarm" title="Active Critical Alarms"></img>
                </div>`,
            filter: {
              term: null,
              type: uiGridConstants.filter.SELECT,
              selectOptions: [{ value: false, label: 'No Alarms' }, { value: true, label: 'Alarms' }]
            }
          },
          {
            name: 'Site', field: 'Name', width: '268', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..." },
            cellTemplate: `<div class="ui-grid-cell-contents flex">
                <div class="self-center flex flex-row gap-1">
                  <a ng-hide="row.entity.ExternalViewLink != null" ng-href="/site/{{row.entity.id}}", target="_blank" title={{row.entity.Notes}}><img class="arrow-fwd pl-2"></img></a>
                  <a ng-show="row.entity.ExternalViewLink != null" ng-href="{{row.entity.ExternalViewLink}}" title='Go To Local Site'><img class="arrow-fwd pl-2"></img></a>
                  <span>{{ COL_FIELD }}</span>
                </div>
              </div>`
          },
          {
            name: 'Last Update', field: 'iodata.timestamp', maxWidth: '208',
            cellTemplate: `<div class="ui-grid-cell-contents flex">
              <span class='self-center'>{{ COL_FIELD | date:grid.appScope.ctrl.dateFilterFormat:grid.appScope.ctrl.timeZone }}</span>
            </div>`
          },
          {
            name: 'Weather', field: 'WeatherJSON', width: '268', enableFiltering: false,
            cellTemplate: `<div class="ui-grid-cell-contents flex justify-between" ng-if="COL_FIELD">
              <div class="self-center flex flex-row gap-2 w-full">
                <i class="fa-solid fa-temperature-half"></i>
                <span class="origin-center">{{grid.appScope.ctrl.tempConvert(COL_FIELD.main.temp)}}{{grid.appScope.ctrl.units === 'imperial' ? '&#8457;' : '&#8451'}}</span>
              </div>
              <div class="self-center flex flex-row gap-2 w-full">
                <i class="fa-solid fa-wind"></i>
                <i class="fa-solid fa-up-long fa-rotate-by" style="--fa-rotate-angle: {{COL_FIELD.wind.deg}}deg;"></i>
                <span class="origin-center">{{grid.appScope.ctrl.speedConvert(COL_FIELD.wind.speed)}}{{grid.appScope.ctrl.units === 'imperial' ? 'mph' : 'km/h'}}</span>
              </div>
              <div class="self-center flex flex-row gap-2 w-full">
                <img class="w{{COL_FIELD.weather[0].icon}} h-6"></img>
                <span class="origin-center">{{COL_FIELD.weather[0].main}}</span>
              </div>
            </div>`
          },
          {
            name: 'Site Status', field: 'iodata.tags', minWidth: '178', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..." },
            cellTemplate: `<div class="ui-grid-cell-contents flex justify-left gap-2">
              <span class='self-center text-wrap'>{{ COL_FIELD.status | statusValue }}</span>
              <span class='self-center text-wrap'>{{ COL_FIELD.opStatus | statusValue }}</span>
            </div>`
          },
          {
            name: 'Utility Status', field: 'iodata.tags.U.status', width: '178', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..." },
            cellTemplate: `<div class="ui-grid-cell-contents">
            <div class="w-full flex flex-wrap justify-center h-full overflow-y-auto"  
              ng-class="{ 
                'bg-gs-unknown': (COL_FIELD == undefined || COL_FIELD == null),
                'bg-gs-warn-fade': (COL_FIELD.indexOf('OFF') > -1), 
                'bg-gs-success-fade': ((COL_FIELD.indexOf('MANUAL') > -1) || (COL_FIELD.indexOf('AUTO') > -1)), 
                'bg-gs-danger-fade': (COL_FIELD.indexOf('FAULT') > -1),
              }">
              <div class='self-center text-wrap'>{{ COL_FIELD | statusValue }}</div>
            </div>
            </div>`
          },
          {
            name: 'Generator Status', field: 'iodata.tags.G.status', width: '178', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..." },
            cellTemplate: `<div class="ui-grid-cell-contents">
            <div class="w-full flex flex-wrap justify-center h-full overflow-y-auto" 
              ng-class="{ 
                'bg-gs-unknown': (COL_FIELD == undefined || COL_FIELD == null),
                'bg-gs-warn-fade': (COL_FIELD.indexOf('OFF') > -1), 
                'bg-gs-success-fade': ((COL_FIELD.indexOf('MANUAL') > -1) || (COL_FIELD.indexOf('AUTO') > -1)), 
                'bg-gs-danger-fade': (COL_FIELD.indexOf('FAULT') > -1),
              }">
              <div class='self-center text-wrap'>{{ COL_FIELD | statusValue }}</div>
            </div>
            </div>`
          },
          {
            name: ' ', width: '32', enableColumnMenu: false, enableFiltering: false, enableSorting: false,
            cellTemplate: `<div class="ui-grid-cell-contents m-0">
              <button class="w-full w-full m-0", type="button", id="siteDropdownMenuButton", data-bs-toggle="dropdown", aria-expanded="false">
                <img class="hamburger-vertical-icon"><img>
              </button>
              <ul class="dropdown-menu dropdown-menu-end", aria-labelledby="siteDropdownMenuButton">
                <li>
                  <a class="dropdown-item", ng-href="/site/{{row.entity.id}}", target="_blank">Open Site Detail</a>
                </li>
              </ul></div>`
          }

        ]
      }
      self.getData();

      setInterval(() => { self.getData() }, 15000);
    }

    highlightFilteredHeader = (row, rowRenderIndex, col, colRenderIndex) => {
      if (col.filters[0].term) {
        return 'header-filtered';
      } else {
        return '';
      }
    };

    getData = () => {
      this.busy = true;
      let selectPromises = [];
      selectPromises.push(this.SiteResource.listlive().$promise);
      selectPromises.push(this.ScheduleResource.findAllPending().$promise);
      this.$q.all(selectPromises).then((results: any[][]) => {
        if (results[0]) {
          this.gridOptions['idle'].data = results[0].filter(this.idleFilter);
          this.gridOptions['running'].data = results[0].filter(this.runningFilter);
        }
        if (results[1]) {
          this.pendingList = results[1];
        }
        this.busy = false;
      });
    }

    runningFilter = (site) => {
      if (site.iodata.tags) {
        return (this.isTrue(site.iodata.tags.running));
      } else {
        return false;
      }

    }

    idleFilter = (site) => {
      if (site.iodata.tags) {
        return (this.isFalse(site.iodata.tags.running));
      } else {
        return true;
      }
    }

    utilBadge = (status) => {
      let result = 'bg-gs-dark';
      if (status == undefined || status == null) {
        result = 'bg-gray-400';
      }
      if (status) {
        if (status.indexOf('OFF') > -1) {
          result = 'bg-red-500';
        }
      }
      return result;
    }

    genBadge = (status) => {
      let result = 'bg-gs-dark';
      if (status == undefined || status == null) {
        result = 'bg-gray-400';
      }
      if (status) {
        if (status.indexOf('ON') > -1) {
          result = 'bg-gs-light';
        }
      }
      return result;
    }

  }
  angular.module('gsapp').controller('DashboardController', DashboardController);
}