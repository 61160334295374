namespace GSapp.interfaces {
  'use strict';

  export interface ISite extends ng.resource.IResource<ISite>
  {
    IoTHub: string;
    IoTDeviceId: string;
    IoTPrimaryKey: string;
    Type: string;
    Name: string;
    Address1: string;
    Address2: string;
    Address3: string;
    Address4: string;
    Locality: string;
    Region: string;
    Postcode: string;
    Country: string;
    ContactName: string;
    ContactEmail: string;
    ContactPhone: string;
    TimeZone: string;
    Location: any;
    IP: string;
    Notes: string;
    MonitoringContract: boolean;
    ServiceContract: boolean;
    Live: boolean;
    LiveDate: Date;
    NotMonitored: boolean;
    NotMonitoredDate: Date;
    Closed: boolean;
    ClosedDate: Date;
    ControlsDisabled: boolean;
    ControlsDisabledDate: Date;
    ControlsDisabledBy: number;
    OwnerId: number;
    CustomerId: number;
    EndCustomerId: number;
    SalesPersonId: number;
    ReportManagerId: number;
    UtilityCompanyId: number;
    UtilityCompanyAccount: string;
    UtilityApprovalRequired: boolean;
    FuelManagement: boolean;
    DEFManagement: boolean;
    CreatedById: number;
    UpdatedById: number;
  }

  export interface ISiteResource extends ng.resource.IResourceClass<ISite>
  {
    create(any): any;
    list(): any[];
    listmin(): any[];
    listlive(): any[];
    get(): any;
    get(number): any;
    update(number, any) : any;
    getSiteOneLine(number): any;
    getDataByUnit(any): any;
    getDataByUnitFlat(any): any;
    getRawData(any): any;
    getMapSiteData(any): any;
    getDashboardChartData(any): any;
    getDashboardData(any): any;
    getAlarmHistory(any): any;
    getAlarmCodes(any): any;
    // getRawDataFlat(any): any;
    updateOneLine(any): any;
    updateAlarmNotify(any): any;
    sendCommand(any): any;
    updateSiteCommands(any): any;
  }
}