namespace GSapp.controllers {
  'use strict';

  export class ReportController extends controllers.BaseController {
    busy: boolean = false;
    loading: boolean = false;
    reportList: any = null;
    reportFilter: string = '';
    siteList: any = null;
    reportData: any = null;
    selectedReport: any = null;
    options: any = {
      site: null,
      startDate: null,
      endDate: null
    }
    downloadReport: boolean = false;
    isXLSX: boolean  = false;

    static readonly $inject = [
      '$sce',
      'SiteResource',
      'SystemResource',
      '$window', 
      '$q',
      '$cookies',
      'ReportResource'
    ];

    constructor(
      public $sce: ng.ISCEService,
      public SiteResource: interfaces.ISiteResource,
      public SystemResource: interfaces.ISystemResource,   
      public $window: ng.IWindowService,
      public $q: ng.IQService,
      public $cookies: any,
      public ReportResource: interfaces.IReportResource ) {
        super(SystemResource, $window, $q, $cookies, ReportResource);
        
        let self = this;

        self.loading = true;
        let promises = [];
        promises.push(self.ReportResource.list().$promise);
        promises.push(self.SiteResource.listmin().$promise);
        this.$q.all(promises).then((results: any[][]) => {
          if (results[0]) {
            this.reportList = results[0];
          }
          if (results[1]) {
            this.siteList = results[1];
          }
          self.loading = false;
        });
      }

      getReportURL = ( format:string ) => {
        if(!this.checkOptions) {
          toastr.error("All report options must be configured.");
        } else {
          this.busy = true;
          let reportType: string;
          let streamFormat: string;
          this.isXLSX = false;
          switch (format) {
            case 'HTML':
              reportType = 'html';
              streamFormat = 'text/html';
              break;
            case 'XLSX':
              this.isXLSX = true;
              reportType = 'xlsx';
              streamFormat = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
              break;
            default:
              reportType = 'chrome-pdf';
              streamFormat = 'application/pdf';
              break;
          }
          this.ReportResource.getReport( { reportName : this.selectedReport.name, outputType : reportType, reportOptions: this.options } ).$promise.then(reportOutput => {
            if(!reportOutput.hasOwnProperty('success')) {
              this.reportData = null;
              let reportBuff = new Uint8Array(reportOutput.data);
              let pageData = new Blob([reportBuff], {type: streamFormat} );
              let urlObject = URL.createObjectURL(pageData);
              if (this.isXLSX === false) {
                this.reportData = this.$sce.trustAsResourceUrl(urlObject);
              }
              if(this.downloadReport) {
                let downloadObject = URL.createObjectURL(pageData);
                let a = document.createElement("a");
                document.body.appendChild(a);
                a.href = downloadObject;
                a.download = this.selectedReport.description + ' ' + moment().format("YYYYMMDDHHmm") + '.' + format.toLowerCase();
                a.click();
                URL.revokeObjectURL(a.href);
              }
            } else {
              this.reportData = reportOutput;
            }
            this.busy = false;
          });
        }
      }

      checkOptions = () => {
        let okToRun = true;
        for(let option in this.selectedReport.optionsJSON) {
          if (option) {
            if(!this.options[option]) {
              okToRun = false;
            }
          }
        }
        return okToRun;
      }

      setReport = (report) => {
        if (this.selectedReport) {
          this.options = {
            site: null,
            startDate: null,
            endDate: null
          }
          this.selectedReport = null;
          if (report.shortId != this.selectedReport.shortId) {
            this.selectedReport = JSON.parse(JSON.stringify(report));
          }
        } else {
          this.selectedReport = JSON.parse(JSON.stringify(report));
        }
      }
    }
    angular.module('gsapp').controller('ReportController', ReportController);
  }

