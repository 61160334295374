namespace GSapp.interfaces {
  'use strict';

  export interface ILogin extends ng.resource.IResource<ILogin> {
    id:                   number;
    LoginName:            string;
    Password:             string;
    PasswordConfirm:      string;
    roles:                string[];
    accessToken:          string;
    setupMFA:             boolean;
    setupMFASMS:          boolean;
    showResendOTP:        boolean;
    newMFA:               string;
    showMFA:              boolean;
    qrcode:               string;
    mfaCode:               string;
    success:              boolean;
    message:              string;
  }

  export interface ILoginResource extends ng.resource.IResourceClass<ILogin>{
    get(): ILogin;
    signin(ILogin): ILogin;
    signup(ILogin): ILogin;
    resendMFA(object) : any;
    signout(): ILogin;
    sendReset(object): ILogin;
    checkToken(object): ILogin;
    updatePassword(object): ILogin;
  }
}
