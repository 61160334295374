namespace GSapp.interfaces {
  'use strict';

  export interface IRedLion extends ng.resource.IResource<IRedLion>
  {
    Model: string;
    Serial: string;
    DefaultPassword: string;
    CellModel: string;
    CellSerial: string;
    UPC: string;
    IMEI: string;
    IP: string;
    PhoneNumber: string;
    ICCID: string;
    SiteId: number;
    APN: string;
  }

  export interface IRedLionResource extends ng.resource.IResourceClass<IRedLion>
  {
    create(any): any;
    list(): any[];
    get(): any;
    get(number): any;
    update(number, any) : any;
  }
}