namespace GSapp.interfaces {
  'use strict';

  export interface IRole extends ng.resource.IResource<IRole>
  {
      id: number;
      Name: string;
  }

  export interface IRoleResource extends ng.resource.IResourceClass<IRole>
  {
    create(any): any;
    list(): any[];
    get(): any;
    get(number): any;
    update(number, any) : any;
    deleteRole(number): any;
    listPermission(): any[];
    addPermission(any): any;
    updatePermission(number, any): any;
    deletePermission(number): any;
  }
}