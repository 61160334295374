namespace GSapp.interfaces {
  'use strict';

  export interface ISupplier extends ng.resource.IResource<ISupplier>
  {
    Name : string;
    Address1 : string;
    Address2 : string;
    Address3 : string;
    Address4 : string;
    Locality : string;
    Region : string;
    Postcode : string;
    Country : string;
    ContactName : string;
    ContactEmail : string;
    ContactPhone : string;
    TimeZone : string;
    Diesel: boolean;
    DEF: boolean;
    Propane: boolean;
    Notes: string;
    Deleted: boolean;
  }

  export interface ISupplierResource extends ng.resource.IResourceClass<ISupplier>
  {
    create(any): any;
    list(): any[];
    get(): any;
    get(number): any;
    update(number, any) : any;
  }
}