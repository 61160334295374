namespace GSapp.filters
{
  'use strict';

  export class formatValue
  {
    /** 
     * @author Michael Guenther
     * @name formatValue
     * @type filter
     * @description Return a blank space or a formatted float with 'precision' decimal places.
     * @param input any value.  Numbers and special cases will be formatted and returned.
     * @param precision the number of decimal places to format the float with.  null defaults to 1.
     * @param invalid a value that will be treated as 'bad' and not displayed as a number.  null will not check for invalid values.
     * @param status a value that will be treated as 'comm fail' and not displayed as a number.
     * @returns a formatted float value or special cases (&nbsp;, MBErr, N/A, or original value).
     * @memberof formatValue
    */
    static filter() {

      return (input : any, precision : any, invalid : any, status : any) => {
        let mberr = false;
        //If the value provided is not null, format the value.
        if(_.isUndefined(input) || _.isNull(input)) {
          return '---';
        }
        if(!_.isUndefined(status) && !_.isNull(status)) {
          if((status & 3) != 1) {
            //If there is a comm failure, return 'MBErr' instead of a number.
            mberr = true;
          }
        }
        //Check to see if the value provided is a number or not.
        if(isNaN(input)) {
          //Don't format anything, return the original value.
          return input;
        }
        //Check to see if invalid was specified and if the value is invalid.
        if(invalid && (invalid <= input)) {
          return 'n/a';
        } 
        //Check to see if a precision was specified.
        if(precision != null) {
          //Specified precision.
          return mberr ? '[' + input.toFixed(precision) + ']' : input.toFixed(precision);
        }
        return mberr ? '[' + input.toFixed(1) + ']' : input.toFixed(1);
      };
    }
  }

  angular.module('gsapp').filter('formatValue',  formatValue.filter);
}
