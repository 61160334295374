namespace GSapp.interfaces {
  'use strict';

  export interface ISystem extends ng.resource.IResource<ISystem>
  {
    Name : string;
  }

  export interface ISystemResource extends ng.resource.IResourceClass<ISystem>
  {
    listtz(): any[];
    listmaplayers(): any[];
    liststormlayers(): any[];
    listcountry(): any[];
    liststate(number): any[];
    listcity(number): any[];
    listUnitTypes(): any[];
    listVarRefs(): any;
    validateToken(): any;
  }
}