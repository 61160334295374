namespace GSapp.controllers {
  'use strict';

  export class CommandGroupsController extends controllers.BaseController {
    busy: boolean = false;
    loading: boolean = false;
    commandGroups: any = null;
    availableGroups: any = null;
    availableSites: any = null;
    searchText: string = null;
    selectedGroup: any = { id: 0, Disabled: true };
    commandGroupToEdit: any = { Name: 'Test' };
    gridOptions: any = {};
    gridApi: any = {};
    
    static readonly $inject = [
      'SiteResource',
      'CommandGroupResource',
      'uiGridConstants',
      'SystemResource',
      '$window', 
      '$q', '$cookies', 'ReportResource'
    ];

    constructor(
      public SiteResource: interfaces.ISiteResource,
      public CommandGroupResource: interfaces.ICommandGroupResource,
      public uiGridConstants: any,
      public SystemResource: interfaces.ISystemResource,   
      public $window: ng.IWindowService,
      public $q: ng.IQService,
      public $cookies: any,
      public ReportResource: interfaces.IReportResource ) {
        super(SystemResource, $window, $q, $cookies, ReportResource);
        
        let self = this;
        
        self.gridOptions['cmdGroup'] = {
          onRegisterApi: function(gridApi){
            self.gridApi['cmdGroup'] = gridApi;
          },
          enableFiltering: true,
          enableRowHashing:false,
          enableHorizontalScrollbar: this.uiGridConstants.scrollbars.WHEN_NEEDED,
          rowHeight: this.gridRowHeight,
          data: [],
          columnDefs :[
            { name: 'Group', field: 'GroupName', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."} },
            { name: 'Site', field: 'SiteName', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."} },
            { name: 'User', field: 'LoginName', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."} },
            { name: 'Start Command', field: 'StartCmd', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."} },
            { name: 'Start Description', field: 'StartCmdStr', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."} },
            { name: 'Stop Command', field: 'StopCmd', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."} },
            { name: 'Stop Description', field: 'StopCmdStr', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."} },
            { name: 'Disabled', field: 'Disabled',
              cellTemplate: `<div class="ui-grid-cell-contents">
                <img class="self-center circle-cross-icon filter-white origin-center" ng-show="COL_FIELD" title="Disabled"></img>
                </div>`,
              filter: {
                term: null,
                type: uiGridConstants.filter.SELECT,
                selectOptions: [ { value: true, label: 'Disabled' }, { value: false, label: 'Enabled'} ]
              }
            },
            { name: ' ', width: '32', enableFiltering: false, enableSorting: false,
            cellTemplate: `<div class="ui-grid-cell-contents m-0">
              <button class="w-full w-full m-0", type="button", id="cmdGroupDropdownMenuButton", data-bs-toggle="dropdown", aria-expanded="false">
                <img class="hamburger-vertical-icon"><img>
              </button>
              <ul class="dropdown-menu dropdown-menu-end", aria-labelledby="cmdGroupDropdownMenuButton">
                <li>
                  <button class="dropdown-item" ng-click="grid.appScope.ctrl.editCommandGroup(row.entity)">Edit</button>
                </li>
              </ul></div>`
            }
          ]
        }

        self.getData();

      }
     
      getData = () => {
        this.busy = true;
        let selectPromises = [];
        selectPromises.push(this.CommandGroupResource.findAll().$promise);
        selectPromises.push(this.CommandGroupResource.findOne({id: this.selectedGroup.id}).$promise);
        this.$q.all(selectPromises).then((results: any[][]) => {
          if (results[0]) {
            this.availableGroups = results[0];
            this.availableGroups.unshift({id:0, Name:"All Groups", Disabled: true});
          }
          if (results[1]) {
            if (results[1].length > 0) {
              this.gridOptions['cmdGroup'].data = results[1];
            }
          }
          this.busy = false;
        });
      }

      addSiteToGroup = () => {
        this.commandGroupToEdit['SitesAndCommandsJSON'].push({SiteId: null, StartCmd: null, StopCmd: null});
      }

      createCommandGroup = () => {
        let self = this;
        self.commandGroupToEdit = {
          UserId: null,
          SitesAndCommands: '[]',
          Name: null,
          Disabled: false,
          SitesAndCommandsJSON: []
        }
        self.SiteResource.listmin().$promise.then((results) => {
          self.availableSites = {};
          for (let site of results) {
            self.availableSites[site.id] = site;
          }
          $("#editCommandGroupModal").modal("show");
        });
      }

      editCommandGroup = (group:any) => {
        let self = this;
        let selectPromises = [];
        selectPromises.push(self.SiteResource.listmin().$promise);
        selectPromises.push(self.CommandGroupResource.getOne({id: group.id}).$promise);
        this.$q.all(selectPromises).then((results) => {
          if(results[0]){
            self.availableSites = {};
            for (let site of results[0]) {
              self.availableSites[site.id] = site;
            }
          }
          if (results[1]) {
            self.commandGroupToEdit = results[1];
          }
          $("#editCommandGroupModal").modal("show");
        });
      }

      saveGroup = () => {
        let valid = true;
        if(!this.commandGroupToEdit.Name) {
          valid = false;
        }
        // Check the sites/commands.
        if(this.commandGroupToEdit.SitesAndCommandsJSON.length == 0) {
          valid = false;
        }
        for (let cmd of this.commandGroupToEdit.SitesAndCommandsJSON) {
          if(!cmd.SiteId) {
            valid = false;
          }
          if(!cmd.StartCmd) {
            valid = false;
          }
          if(!cmd.StopCmd) {
            valid = false;
          }
        }
        if (valid) {
          this.commandGroupToEdit.SitesAndCommands = angular.toJson(this.commandGroupToEdit.SitesAndCommandsJSON);
          if(this.commandGroupToEdit.id) {
            this.CommandGroupResource.update({id: this.commandGroupToEdit.id}, this.commandGroupToEdit).$promise.then((result) => {
              $("#editCommandGroupModal").modal("hide");
              this.getData();
            });
          } else {
            this.CommandGroupResource.create(this.commandGroupToEdit).$promise.then((result) => {
              $("#editCommandGroupModal").modal("hide");
              this.getData();
            });
          }
        }
      }

      startSelectedGroup = () => {
        this.CommandGroupResource.startGroup(this.selectedGroup).$promise.then(result => {
          if(result.success) {
            toastr.success("Commands sent.");
          } else {
            toastr.error("Failed to send commands: " + result.message);
          }
        });
      }

      stopSelectedGroup = () => {
        this.CommandGroupResource.stopGroup(this.selectedGroup).$promise.then(result => {
          if(result.success) {
            toastr.success("Commands sent.");
          } else {
            toastr.error("Failed to send commands: " + result.message);
          }
        });
      }

      getAvailableGroups = () => {
        this.busy = true;
        this.CommandGroupResource.findAll().$promise.then((results) => {
          this.availableGroups = results;
          this.availableGroups.unshift({id:0, Name:"All Groups", Disabled: true});
          this.busy = false;
        })
      }

      getGroup = () => {
        this.busy = true;
        this.CommandGroupResource.findOne({id: this.selectedGroup.id}).$promise.then((results) => {
          this.gridOptions['cmdGroup'].data = results;
          this.busy = false;
        });
      }

    }
    angular.module('gsapp').controller('CommandGroupsController', CommandGroupsController);
  }