namespace GSapp.controllers {
  'use strict';

  export class HeadController extends controllers.BaseController {
    
    static readonly $inject = [
      'SystemResource',
      '$window', 
      '$q',
      '$cookies',
      'ReportResource'
    ];

    constructor(
      public SystemResource: interfaces.ISystemResource,   
      public $window: ng.IWindowService,
      public $q: ng.IQService,
      public $cookies: any,
      public ReportResource: interfaces.IReportResource ) {
        super(SystemResource, $window, $q, $cookies, ReportResource);

        let self = this;

      }

    }
    angular.module('gsapp').controller('HeadController', HeadController);
}