namespace GSapp.interfaces {
  'use strict';

  export interface IUtility extends ng.resource.IResource<IUtility>
  {
    Name : string;
    Address1 : string;
    Address2 : string;
    Address3 : string;
    Address4 : string;
    Locality : string;
    Region : string;
    Postcode : string;
    Country : string;
    ContactName : string;
    ContactEmail : string;
    ContactPhone : string;
    TimeZone : string;
    Notes: string;
    Deleted: boolean;
  }

  export interface IUtilityResource extends ng.resource.IResourceClass<IUtility>
  {
    create(any): any;
    list(): any[];
    get(): any;
    get(number): any;
    update(number, any) : any;
  }
}