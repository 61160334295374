namespace GSapp.interfaces {
  'use strict';

  export interface IUser extends ng.resource.IResource<IUser>
  {
      id: number;
      LoginName: string;
      FirstName: string;
      LastName: string;
      AllowLogin: boolean;
      Locked: boolean;
      Admin: boolean;
      Monitoring: boolean;
      Service: boolean;
      NoAccess: boolean;
  }

  export interface IComm extends ng.resource.IResource<IComm>
  {
    id: number;
    UserId: number,
    Type: string,
    Value: string,
    SortOrder: number
  }

  export interface IUserResource extends ng.resource.IResourceClass<IUser>
  {
    create(any): any;
    list(): any[];
    get(): any;
    get(number): any;
    getUserRoles(number): any;
    setUserRoles(number): any;
    update(number, any) : any;
    addComm(any): any;
    updateComm(number, any): any;
    deleteComm(any): any;
  }
}
