namespace GSapp.interfaces {
  'use strict';

  export interface ICommandGroup extends ng.resource.IResource<ICommandGroup>
  {
    id: number;
    UserId: number;
    Name: string;
    SitesAndCommands: string;
    Disabled: boolean;
  }

  export interface ICommandGroupResource extends ng.resource.IResourceClass<ICommandGroup>
  {
    create(any) : ICommandGroup,
    getOne(number): ICommandGroup,
    findAll() : ICommandGroup[],
    findOne(number) : any,
    update(number, any): ICommandGroup,
    startGroup(any): any,
    stopGroup(any): any
  }
}